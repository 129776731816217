import './HomeWorld.css';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

function HomeWorld() {
  const { t } = useTranslation();
  return (
    <div className="home-wagzakworld-style">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 d-flex justify-content-center align-items-center">
          <div className="col-md-7 col-12 text-center order-md-1 order-1"></div>

          <div
            className="col-md-5 col-12 text-center order-md-2 order-2 mb-style"
            style={{ padding: '5%' }}
          >
            <div
              className="card testing"
              style={{
                background: 'transparent',
                border: 'none',
                marginBottom: '30%',
              }}
            >
              <img
                src="/images/wagzakApp/Wagzak App.svg"
                alt=""
                className="card-img "
                style={{ maxWidth: '100%', height: '100%' }}
              />
              <div className="card-img-overlay d-flex flex-column align-items-bottom justify-content-center">
                <div className="">
                  <p className="card-text world-text-1  ">
                    Digital Playground
                  </p>
                  <h5 className="card-title world-text-2 ">
                    Wag:zak App
                  </h5>
                  <p className="card-text world-text-3 display-8">
                    <Trans
                      i18nKey="homePage.wagzakworld-text"
                      components={{ 1: <br /> }}
                    />
                  </p>
                  
                  <Link to={`/wagzakapp`}  target="_blank">
                    <button type="button" className="wagzakworld-btn">
                      <span>
                        <Trans
                          i18nKey="Button.Learnmore"
                          components={{ 1: <br /> }}
                        />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeWorld;
