import ButtonPanel from '../../home/containers/ButtonPanel';
import React, { useEffect, useState } from 'react';

function CharactersHero({ analytics }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headerSvg = windowWidth < 767
    ? "/images/characters/Character_Header_mb.svg"
    : "/images/characters/Character_img_header_dk.svg";

  return (
    <div className="characters-container-style"  >
      <img src={headerSvg} alt="Header SVG" style={{ backgroundColor: 'white', transition: 'opacity 0.4s ease-in-out'  }} />
    
    </div>
  );

}

export default CharactersHero;