import FooterCharacters from '../footer/FooterCharacters';
import FooterContacts from '../footer/FooterContacts';
import FooterIconsTwo from '../footer/FooterIconsTwo';
import FooterSec from '../footer/FooterSec';

import Header from '../header/Header';
import "./Policy.css"
import '../../pages/playjam/PlayjamPage.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Policy({ analytics }) { 

    const { t, i18n } = useTranslation();
    const language = i18n.language.split('-')[0] || 'en';
 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [language]);

    return (
        <>
            <motion.div
                initial={{ opacity: 0, transition: { duration: 0.10 } }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.10 } }}
            >
                <section  id="beginning-of-playjam">
                    <Header 
                        alwaysBlue={true}
                    />
                </section>

                <section className="privacy-policy">

                    {/* korean */}
                    {language === 'kr' && (
                        <div className="container ">
                            <div className="policy-style">
                                <h3>주식회사 서커스컴퍼니 개인정보 처리방침</h3>
                                <p style={{ marginTop: '60px' }}>
                                    주식회사 서커스컴퍼니(이하 ‘서커스컴퍼니’라 한다)는 개인정보
                                    보호법 제30조에 따라 서커스AR앱, 서커스컴퍼니 홈페이지 정보주체의
                                    개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
                                    있도록 하기 위하여 다음과 같이 개인정보 처리지침을
                                    수립·공개합니다.
                                </p>
                                <div className="pr_box" style={{ marginTop: '42px' }}>
                                    <p className="pr-tit">제1조(개인정보의 처리목적)</p>
                                    <p className="pr-comm">서커스컴퍼니는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                                    <p className="pr-comm fixed"><span>1. </span>홈페이지 회원 가입 및 관리 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.</p>
                                    <p className="pr-comm fixed"><span>2. </span>재화 또는 서비스 제공 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
                                    <p className="pr-comm fixed"><span>3. </span>고충처리 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제2조(개인정보의 처리 및 보유기간)</p>
                                    <p className="pr-comm fixed"><span>① </span>서커스컴퍼니는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
                                    <p className="pr-comm fixed"><span>② </span>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                                    <p className="pr-comm fixed"><span>1. </span>홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴시까지 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지</p>
                                    <p className="pr-comm fixed"><span>1: </span>관계 법령 위반에 따른 수사·조사 등이 진행중인 경우에는 해당 수사·조사 종료시까지</p>
                                    <p className="pr-comm fixed"><span>2: </span>홈페이지 이용에 따른 채권·채무관계 잔존시에는 해당 채권·채무관계 정산시까지</p>
                                    <p className="pr-comm fixed"><span>2. </span>재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지</p>
                                    <p className="pr-comm fixed"><span>1: </span>「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록 <br />- 표시·광고에 관한 기록 : 6월 <br />- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 <br />- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년</p>
                                    <p className="pr-comm fixed"><span>2: </span>「통신비밀보호법」제41조에 따른 통신사실확인자료 보관<br />- 가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년 <br />- 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제3조(개인정보의 제3자 제공)</p>
                                    <p className="pr-comm">서커스컴퍼니는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제4조(정보주체의 권리·의무 및 행사방법)</p>
                                    <p className="pr-comm fixed"><span>① </span>정보주체는 서커스컴퍼니에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
                                    <p className="pr-comm fixed"><span>1. </span>개인정보 열람요구</p>
                                    <p className="pr-comm fixed"><span>2. </span>오류 등이 있을 경우 정정 요구</p>
                                    <p className="pr-comm fixed"><span>3. </span>삭제요구</p>
                                    <p className="pr-comm fixed"><span>4. </span>처리정지 요구</p>
                                    <p className="pr-comm fixed"><span>② </span>제1항에 따른 권리 행사는 서커스컴퍼니에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 서커스컴퍼니는 이에 대해 지체없이 조치하겠습니다.</p>
                                    <p className="pr-comm fixed"><span>③ </span>정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 서커스컴퍼니는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</p>
                                    <p className="pr-comm fixed"><span>④ </span>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
                                    <p className="pr-comm fixed"><span>⑤ </span>정보주체는 개인정보 보호법 등 관계법령을 위반하여 서커스컴퍼니가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제5조(처리하는 개인정보 항목)</p>
                                    <p className="pr-comm">서커스컴퍼니는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                                    <p className="pr-comm fixed"><span>1. </span>홈페이지 회원 가입 및 관리 <br />-필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소, 아이핀번호 <br />-선택항목 : 결혼여부, 관심분야</p>
                                    <p className="pr-comm fixed"><span>2. </span>서커스 AR앱<br />-필수항목 : 스마트폰의 기기 ID<br />-선택항목 : 성명, 전화번호, 이메일 주소, 주소, 성별, 관심분야</p>
                                    <p className="pr-comm fixed"><span>3. </span>재화 또는 서비스 제공 <br />-필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일주소, 아이핀번호, 신용카드번호, 은행계좌정보 등 결제정보<br />-선택항목 : 관심분야, 과거 구매내역</p>
                                    <p className="pr-comm fixed"><span>4. </span>인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다. <br />IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제6조(개인정보의 파기)</p>
                                    <p className="pr-comm fixed"><span>① </span>서커스컴퍼니 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
                                    <p className="pr-comm fixed"><span>② </span>정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
                                    <p className="pr-comm fixed"><span>③ </span>개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
                                    <p className="pr-comm fixed"><span>1. </span>파기절차<br />서커스컴퍼니는 파기 사유가 발생한 개인정보를 선정하고, 서커스컴퍼니의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p>
                                    <p className="pr-comm fixed"><span>2. </span>파기방법<br />서커스컴퍼니는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 로우레벨포맷(Low Level Format) 등의 기술적 방법을 이용하여 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제7조(개인정보의 안전성 확보조치)</p>
                                    <p className="pr-comm">서커스컴퍼니는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                                    <p className="pr-comm fixed"><span>1. </span>관리적 조치 : 내부관리계획 수립·시행, 정기적 직원 교육 등</p>
                                    <p className="pr-comm fixed"><span>2. </span>기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</p>
                                    <p className="pr-comm fixed"><span>3. </span>물리적 조치 : 전산실, 자료보관실 등의 접근통제</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제8조(개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</p>
                                    <p className="pr-comm fixed"><span>① </span>서커스컴퍼니는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
                                    <p className="pr-comm fixed"><span>② </span>쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
                                    <p className="pr-comm fixed"><span>가. </span>쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</p>
                                    <p className="pr-comm fixed"><span>나. </span>쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 - 인터넷 옵션 - 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</p>
                                    <p className="pr-comm fixed"><span>다. </span>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제9조(개인정보 보호책임자)</p>
                                    <p className="pr-comm fixed"><span>① </span>서커스컴퍼니는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. ▶ 개인정보 보호책임자</p>
                                    <p className="pr-comm fixed">담당자 : 박선욱 대표<br />연락처 : 02-6261-2330, thanks@cscom.io <br />※ 개인정보 보호 담당부서로 연결됩니다.</p>
                                    <p className="pr-comm fixed">▶ 개인정보 보호 담당부서<br />담당자 : 박선욱 대표<br />연락처 : 02-6261-2330, thanks@cscom.io</p>
                                    <p className="pr-comm fixed"><span>② </span>정보주체께서는 서커스컴퍼니의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 서커스컴퍼니는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제10조(개인정보 열람청구)</p>
                                    <p className="pr-comm">정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 서커스컴퍼니는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
                                    <p className="pr-comm fixed">▶ 개인정보 열람청구 접수·처리 부서<br />담당자 : 박선욱 대표<br />연락처 : 02-6261-2330, thanks@cscom.io</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제11조(권익침해 구제방법)</p>
                                    <p className="pr-comm">정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 서커스컴퍼니와는 별개의 기관으로서, 서커스컴퍼니의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다</p>
                                    <p className="pr-comm fixed"><span>▶ </span>개인정보 침해신고센터 (한국인터넷진흥원 운영) <br />- 소관업무 : 개인정보 침해사실 신고, 상담 신청<br />- 홈페이지 : privacy.kisa.or.kr <br />- 전화 : (국번없이) 118 <br />- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터</p>
                                    <p className="pr-comm fixed"><span>▶ </span>개인정보 분쟁조정위원회<br />- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br />- 홈페이지 : www.kopico.go.kr <br />- 전화 : (국번없이) 1833-6972<br />- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층</p>
                                    <p className="pr-comm fixed"><span>▶ </span>대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)<br />▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">제12조(개인정보 처리방침 변경)</p>
                                    <p className="pr-comm fixed"><span>① </span>이 개인정보 처리방침은 2018. 06. 01부터 적용됩니다.</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* english */}
                    {language === 'en' && (
                        <div className="container">
                            <div className="policy-style">
                                <h3>PRIVACY  POLICY</h3>
                                <p style={{ marginTop: '60px' }}>
                                    We at CIRCUS COMPANY Ltd. established and operate these Guidelines to protect the personal information of the subjects of information handled at our AR App and homepage and deal with customers’ complaints more efficiently in accordance with the Personal Information Protection Act, Article 30.
                                </p>
                                <div class="pr_box" style={{ marginTop: '42px' }}>
                                    <p class="pr-tit">Article 1 (Purpose of Processing Personal Information)</p>
                                    <p class="pr-comm">We process personal information for the following purpose. We do not use personal information for purposes other than what is stated in the following. If there are any changes made to these purposes, we will take required steps including obtaining consent, pursuant to Article 18 of the Personal Information Protection Act. </p>
                                    <p class="pr-comm fixed"><span>1. </span>Processing personal information for the purpose of recruiting and managing homepage members and checking proceedings for members, identification/certification of members concerning our service, maintenance/managment of members’ qualification, identity verification operated by limited verification of identity, prevention of dishonest use of our service, obtaining legal agent’s consent concerning the processing of personal information of a minor (under the age of 14), issuance of notices, handling complaints, etc.</p>
                                    <p class="pr-comm fixed"><span>2. </span>Processing personal information for the purpose of provision of delivery for goods or services, service provisions, dispatch of contract documents or invoices, provision of content, provision of customized services, identity verification, age verification, fee payment, collection of what we are entitled to receive, etc.</p>
                                    <p class="pr-comm fixed"><span>3. </span>Processing personal information for the purpose of verification of identity of those lodging their complaints, checking the content of their complaints, communication for fact-finding, notice of the result of handling their </p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 2 (Period for Processing and Keeping Personal Information)</p>
                                    <p className="pr-comm fixed"><span>① </span>Our processing and keeping of personal information is done within the period stipulated in relevant laws or consented by the subjects of information.</p>
                                    <p className="pr-comm fixed"><span>② </span>We process and keep personal information during each period stated in the following:</p>
                                    <p className="pr-comm fixed"><span>1. </span>Homepage membership subscription and management: Until their membership withdrawal Or until the time stated in the following:</p>
                                    <p className="pr-comm fixed"><span>1: </span>Completion of investigation about alleged violation of related laws</p>
                                    <p className="pr-comm fixed"><span>2: </span>Completion of debtor-creditor relationship settlement concerning the use of our homepage</p>
                                    <p className="pr-comm fixed"><span>2. </span>Provision of goods or services: Until completion of supply/provision and price payment Or until the time stated in the following:</p>
                                    <p className="pr-comm fixed"><span>1: </span>Concerning records about transactions such as marking, advertisement, content of contracts signed, and their execution under the Act on the Consumer Protection in the Electronic Commerce, etc. <br />- Records about marking/advertisement: 6 months <br />- Records about contract/subscription cancellation, price payment, supply of goods, etc.: 5 years<br />- Records about disputes or consumers complaints: 3 years</p>
                                    <p className="pr-comm fixed"><span>2: </span>Keeping of communications-related fact confirmation data stipulated in the Protection of Communications Secrets Act, Article 41<br />- Date/time of subscribers telecommunication start/finish, counterparty subscribers numbers, frequency of use, materials used to trace the locations of transmission base stations: 1 year <br />- Computer communications, Internet log-in records, materials used to trace places of connection: for 3 months</p>
                                </div>
                                <div class="pr_box">
                                    <p class="pr-tit">Article 3 (Provision of Personal Information to Third Parties)</p>
                                    <p class="pr-comm">We process personal information of the subjects of information only within the extent stated in Article 1 (Purpose of Processing Personal Information) hereof. Our provision of personal information shall be done only with the consent of the information subject and in compliance with the Personal Information Protection Act, Article 17.</p>
                                </div>
                                <div class="pr_box">
                                    <p class="pr-tit">Article 4 (Rights and Obligations of the Information Subjects; How to Exercise Them)</p>
                                    <p class="pr-comm fixed"><span>① </span>The subjects of information may ask us for the following management regarding personal information protection at any time. </p>
                                    <p class="pr-comm fixed"><span>1. </span>Access to their personal information kept by us</p>
                                    <p class="pr-comm fixed"><span>2. </span>Correction of errors in their personal information kept by us</p>
                                    <p class="pr-comm fixed"><span>3. </span>Deletion of their personal information kept by us</p>
                                    <p class="pr-comm fixed"><span>4. </span>Stopping the processing their personal information kept by us </p>
                                    <p class="pr-comm fixed"><span>② </span>The requests stated in the foregoing ① may be made in writing or by phone, email, or fax and we will comply with them without delay. </p>
                                    <p class="pr-comm fixed"><span>③ </span>Where the information subject asks us for the correction of errors in or deletion of their personal information, we will not use or provide it to a third party until the completion of the correction or deletion. </p>
                                    <p class="pr-comm fixed"><span>④ </span>The requests stated in the foregoing ① may be made through the information subject’s agent or trustee. In such a case, a power of attorney shall be submitted to us, using the accompanying form in Schedule 11, Enforcement Decree of the Personal Information Protection Act.</p>
                                    <p class="pr-comm fixed"><span>⑤ </span>We shall not intrude upon the privacy of the information subjects or others in violation of acts including or relevant to the Personal Information Protection Act.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 5 (Processed Personal Information Items)</p>
                                    <p className="pr-comm">Personal Information items processed by us include the following:</p>
                                    <p className="pr-comm fixed"><span>1. </span>Homepage membership subscription and management <br />-Required items: name, DOB, ID, password, address, phone number, gender, email, I-PIN number <br />-Optional items: marital status, interests</p>
                                    <p className="pr-comm fixed"><span>2. </span>AR App<br />-Required items: smartphone device ID<br />-Optional items: name, phone number, email, address, gender, interests</p>
                                    <p className="pr-comm fixed"><span>3. </span>Provision of goods or services <br />-Required items: name, DOB, ID, password, address, phone number, email, I-PIN number, credit card number, <br />bank account number, and other payment-related information<br />-Optional items: interests, past purchase records</p>
                                    <p className="pr-comm fixed"><span>4. </span>The following personal information items may be automatically created and collected in the course of customers’<br />using our Internet-based services. <br />-IP address, cookies, MAC address, service use records, visit history records, bad site use records</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 6 (Destruction of Personal Information)</p>
                                    <p className="pr-comm fixed"><span>① </span>Upon termination of the designated period for keeping personal information or attainment of the purpose of <br />processing them, we will destroy relevant personal information without delay.</p>
                                    <p className="pr-comm fixed"><span>② </span>Where we are required to continue to keep personal information under the law notwithstanding what is stated in the foregoing ①, we will move them to a separate database or place of storage with acceptance from the information subject.</p>
                                    <p className="pr-comm fixed"><span>③ </span>We destroy personal information as follows.</p>
                                    <p className="pr-comm fixed"><span>1. </span>Destruction procedure<br />We choose the personal information items to be destructed, obtain the approval of the personal information <br />manager concerning their destruction, and then destroy the information.</p>
                                    <p className="pr-comm fixed"><span>2. </span>Methods used <br />Personal information recorded or saved in the form of an electronic file is destroyed using an irrecoverable <br />method like Low Level Format. Personal information kept in the form of a paper document is shredded or incinerated.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 7 (Securing Safety of Personal Information)</p>
                                    <p className="pr-comm">We take the following steps to secure the safety of our held personal information.</p>
                                    <p className="pr-comm fixed"><span>1. </span>Administrative steps: establishment/operation of an internal management plan; periodic employee education</p>
                                    <p className="pr-comm fixed"><span>2. </span>Technical steps: management of the rights to access the personal information processing system, installation of the<br />access control system, encoding of unique identification information; installation of security programs</p>
                                    <p className="pr-comm fixed"><span>3. </span>Physical steps: control of access to computer rooms and data storage facilities</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 8 (Matters pertaining to Installation and Operation of Personal Information Automatic Collection Devices and Refusal)</p>
                                    <p className="pr-comm fixed"><span>① </span>We use cookies to save and retrieve information from time to time to provide customized service to customers.</p>
                                    <p className="pr-comm fixed"><span>② </span>Cookies are small amounts of information sent by the server (http) used to operate websites to customers’ computer browsers. They are also stored in hard discs of customers’ PCs.</p>
                                    <p class="pr-comm fixed"><span>a. </span>Purpose of using cookies: Cookies are used to check records of customers’ visits to our service sites and websites, <br />their types of use, frequently searched keywords, status of secured connections, etc. They are also used to provide <br />optimized information to customers.</p>
                                    <p class="pr-comm fixed"><span>b. </span>Customers’ refusal to accept operation of cookies: Customers may express their refusal to use cookies as follows: Tools at the top of a web browser page - Internet Option / Personal Information menu.</p>
                                    <p class="pr-comm fixed"><span>c. </span>Customers’ refusal to accept cookies may result in difficulties while using our customized service.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 9 (Personal Information Manager)</p>
                                    <p className="pr-comm fixed"><span>① </span>We have designated personal information managers as shown below to handle complaints of information subjects about our processing personal information and help compensate them for losses concerning our processing of personal information.<br />▶ Personal Information Manager <br /> Name: Han Ju-hui<br /> Position: Business Support Team Manager<br /> Contact: 02-6261-2330, like@circuscompany.com, 0303-0011-9878<br />※ Your call will be directed to the Personal Information Protection Department. <br />▶ Personal Information Protection Department <br /> Department: Business Support Team<br /> Manager: Han Ju-hui<br /> Contact: 02-6261-2330, like@circuscompany.com, 0303-0011-987</p>
                                    <p className="pr-comm fixed"><span>② </span>We will be immediately respond to and handle any inquiries sent to the personal information protection manager or department made by the information subjects regarding our protection of their personal information, our handling of their complaints, our handling of matters concerning compensation for their loss concerning our processing of personal information, etc during the use of our service or company.</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 10 (Request for Accessing Personal Information)</p>
                                    <p className="pr-comm">Our personnel stated in the following will promptly deal with requests from the information subjects for accessing their personal information kept by us under the Personal Information Protection Act, Article 35.</p>
                                    <p className="pr-comm fixed"><span></span>▶ Department: Business Support Team<br /> Manager: Han Ju-hui<br /> Contact: 02-6261-2330, like@circuscompany.com, 0303-0011-987</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 11 (Relief for Rights Infringement)</p>
                                    <p className="pr-comm">The information subjects may get relief, consulting concerning an infringement of rights related to their personal information from the following agencies.<br />&lsaquo;The following agencies are operating independently of us. You may contact them if you have complaints about our processing of your personal information or you are not satisfied with the way your loss related to your personal information is handled by us.&rsaquo;</p>
                                    <p className="pr-comm fixed"><span></span>▶ Korea Computer Emergency Response Team Coordination Center (KrCERT/CC) [run by the Korea Internet & Security Agency (KISA)]<br />- Business handled: reports on infringement of personal information-related rights; relevant consulting<br />- Homepage: privacy.kisa.or.kr <br />- ☎: 118 (no national code needed)<br />- Address: KrCERT/CC, 3rd Fl., 9 Jinheung-gil (301-2, Bitgaram-dong), Naju-si, Jeollanam-do 58324<br />▶ Personal Information Dispute Mediation Committee (“PIDMC” or “Pico")<br />- Business handled: application for arbitration of personal information-related disputes; mediation of collective disputes<br />- Homepage: www.kopico.go.kr <br />- ☎: 1833-6972 (no national code needed)<br />- Address: 4th Fl., Government Complex 209 Sejong-daero, Jongno-gu, Seoul 03171<br />▶ Internet Crime Investigation Center of the Supreme Prosecutors' Office: ☎02-3480-3573 (www.spo.go.kr)<br />▶ Korean National Police Agency Cyber Bureau: ☎182 (http://cyberbureau.police.go.kr)</p>
                                </div>
                                <div className="pr_box">
                                    <p className="pr-tit">Article 12 (Effectuation)</p>
                                    <p className="pr-comm fixed"><span>① </span>These revised Guidelines shall be enforced starting June 1, 2018.</p>
                                </div>
                            </div>
                        </div>
                    )}
                </section>

                

                <section className="playjamSection5">
                    <FooterSec />
                </section>

                <section className="playjamSection6">
                    <FooterContacts />
                </section>

                <section className="playjamSection7">
                    <FooterCharacters />
                </section>
            </motion.div>
        </>
    );
}

export default Policy;
