 
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'path', 'localStorage', 'querystring', 'navigator'],
      caches: ['cookie'], // If you're using cookies to store language preference
      // Ensure your server is configured to handle cookies if your app is server-side-rendered
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    parseLng: (lng) => {
      // Simplify the language code to only 'en' or 'kr'
      const simplifiedLng = lng.split('-')[0];
      return simplifiedLng === 'en' || simplifiedLng === 'kr' ? simplifiedLng : 'en';
    }
  });

export default i18n;



// // export default i18n;
// import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';

// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//       fallbackLng: "en",
//       debug: true,
//       interpolation: {
//           escapeValue: false // not needed for react as it escapes by default
//       },
//       detection: {
//         order: ['cookie', 'path', 'localStorage', 'querystring', 'navigator'],
//         caches: ['cookie'], // If you're using cookies to store language preference
//         // Ensure your server is configured to handle cookies if your app is server-side-rendered
//       },
//       backend: {
//           loadPath: '/locales/{{lng}}/translation.json',
//       },
//   });

// export default i18n;
