import React from 'react';
import MailchimpForm from '../mailchimpServices/MailchimpForm';
import './FooterSec.css';
import { useTranslation, Trans } from 'react-i18next';


function FooterSubscribe2() {
    const { t } = useTranslation();
    return (
        <div className='container m-top-5 m-bottom-5'>
            <div className='row'>
                <div className='col-md-12 col-sm-12 text-center'>
                    <h1 className='email-title' ><Trans i18nKey="Mailchimp.text2" components={{ 1: <br /> }} /></h1>
                    <p className='content-p-style margin-bottom-5'> <Trans i18nKey="Mailchimp.text-p2" components={{ 1: <br /> }} /> </p>
                    <MailchimpForm />
                </div>
            </div>


        </div>
    );

}
export default FooterSubscribe2;