


import './AboutCrew.css';
import React, { useEffect, useState } from 'react';
import HoverableCard from './HoverableCard';
import BeamingButton from '../../../components/beamingButton/BeamingButton';
import DescriptionModal from '../../../components/roundModal/DescriptionModal';
import crew from '../resources/Crewmembers.json';


function AboutCrew() {
  const [modal, setModal] = useState(false);
  const [modalInformation, setModalInformation] = useState({});

  const openModal = (person) => {
    setModal(true);
    setModalInformation(person);
  };
  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <div className="about-crew-section-style">
        <div className="container">
          <div className="row ">
            <div className="col-1 col-md-1"> </div>
            <div className="col-10 col-md-10 d-flex justify-content-center">
              <h5 className="aboutus-crew-title">Meet our crew</h5>
            </div>
            <div className="col-1 col-md-1"> </div>
          </div>

          <div className="row align-items-center text-center justify-content-center">
            <div className="col-2"></div>
            {/*  all characters container start here */}
            <div className="all-crewmembers-container col-8">
              {/* leejae container */}
              <div className="leejae-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[0])}
                    className="leejae-unfixed-button"
                  >
                    <BeamingButton
                      onClick={() => openModal(crew.crewmembers[0])}
                    />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[0].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* sunpower container */}

              <div className="sunpower-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[1])}
                    className="sunpower-unfixed-button"
                  >
                    <BeamingButton
                      onClick={() => openModal(crew.crewmembers[1])}
                    />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[1].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* grace */}

              <div className="grace-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[2])}
                    className="grace-unfixed-button"
                  >
                    <BeamingButton
                      onClick={() => openModal(crew.crewmembers[2])}
                    />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[2].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* sush container */}
              <div className="sush-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[3])}
                    className="sush-unfixed-button"
                  >
                    <BeamingButton
                      onClick={() => openModal(crew.crewmembers[3])}
                    />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[3].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* wina container */}
              <div className="wina-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[4])}
                    className="wina-unfixed-button"
                  >
                    <BeamingButton />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[4].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* kumush */}
              <div className="kumush-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[5])}
                    className="kumush-unfixed-button"
                  >
                    <BeamingButton />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[5].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* gulzat */}
              <div className="gulzat-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[6])}
                    className="gulzat-unfixed-button"
                  >
                    <BeamingButton />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[6].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* calvin */}

              <div className="calvin-container">
                <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[7])}
                    className="calvin-unfixed-button"
                  >
                    <BeamingButton />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[7].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
              </div>

              {/* tigger */}
              <div className="tigger-container">
              <HoverableCard className="hoverable-card">
                  <div
                    onClick={() => openModal(crew.crewmembers[10])}
                    className="onemad-unfixed-button"
                  >
                    <BeamingButton />
                  </div>
                  <div className="lottie-container">
                    <lottie-player
                      src={crew.crewmembers[10].animation}
                      background="transparent"
                      speed="1"
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </HoverableCard>
                
              </div>

              
            </div>

            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <DescriptionModal
        image={modalInformation.animation}
        name={modalInformation.name}
        position={modalInformation.position}
        description={modalInformation.description}
        modal={modal}
        closeModal={closeModal}
      />
    </>
  );
}

export default AboutCrew;
