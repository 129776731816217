
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Link } from 'react-router-dom'; 
import { HomePage, WagzakAppPage, AboutUsPage, PlayjamPage, CharactersPage, Subscription } from '../pages';
import PlayjamContentDetail from '../pages/playjam/containers/PlayjamContentDetail';
import NotFound from './NotFound';
import Policy from '../components/privacy/Policy';
import Signin from '../components/auth/Signin';
import SignUp from '../components/auth/SignUp'; 
import UserAccount from '../components/auth/UserAccount';
import PrivateRoute from '../components/auth/PrivateRoute';



import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";


import { AnimatePresence } from 'framer-motion'; 
 
const firebaseConfig = {
    apiKey: "AIzaSyA1YqO1JsEmtqueNZCSYKs8vg9_a-FWyOA",
    authDomain: "wagzak-homepage.firebaseapp.com",
    projectId: "wagzak-homepage",
    storageBucket: "wagzak-homepage.appspot.com",
    messagingSenderId: "855242864757",
    appId: "1:855242864757:web:719064815b6fe65e56603c",
    measurementId: "G-C5ZNRWJBB8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function AnimatedRoutes() {
    const location = useLocation() 

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route
                    path='/'
                    element={<HomePage analytics={analytics} />} />

                <Route path='/wagzakapp' element={<WagzakAppPage analytics={analytics} />} />
                <Route path='/aboutus' element={<AboutUsPage analytics={analytics} />} />
                <Route path='/playjam' element={<PlayjamPage analytics={analytics} />} />
                <Route path='/characters' element={<CharactersPage analytics={analytics} />} />
                {/* <Route path='/subscription' element={<Subscription />} /> */}

                <Route path='/policy/privacy' element={<Policy />} />
                <Route path='/playjam/all/:contentID' element={<PlayjamContentDetail analytics={analytics}  />} /> 
                
                {/* <Route path='/account/signin' element={<Signin />} />
                <Route path='/account/signup' element={<SignUp />} /> 
                <Route path='/account/user-data' element={<PrivateRoute><UserAccount /></PrivateRoute>} /> */}




                   
                <Route path='*' element={<NotFound />} />

            </Routes>
        </AnimatePresence>

    )
}

export default AnimatedRoutes