import React, { useLayoutEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TitleAnimation } from '../../../components';

gsap.registerPlugin(ScrollTrigger);

function WagzakAppOverview() {

    return (
        <div className="container flex-container-column m-top-5">
            {/* <TitleAnimation
                text={<Trans i18nKey="wagzakAppPage.section2-title" components={{ 1: <br /> }} />}
                className="content-main-title-ef font-color-purple"
            /> */}
            <h1  className="content-main-title-ef font-color-purple">
                <Trans i18nKey="wagzakAppPage.section2-title" components={{ 1: <br /> }} />
            </h1>
            <p className="content-p-style">
                <Trans i18nKey="wagzakAppPage.section2-text" components={{ 1: <br /> }} />
            </p>
            <img src="/images/wagzakApp/happyfamily.svg" style={{ width: "60%", marginLeft: '10%' }} />
        </div>
    );
}

export default WagzakAppOverview;
