import React, { useState, useEffect } from 'react';
import '@lottiefiles/lottie-player';
import './SplashScreen.css';

function SplashScreen() {
    const [isVisible, setIsVisible] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [loadingText, setLoadingText] = useState('Loading');
    const [lottieWidth, setLottieWidth] = useState('10%');

    useEffect(() => {
        // Timer to cycle the loading dots
        const dotTimer = setInterval(() => {
            setLoadingText(prev => {
                if (prev.length >= 10) return 'Loading';
                return prev + ' .';
            });
        }, 220); // Adjust dot animation speed here

        // Preload the Lottie animation JSON
        const preloadLottieAnimation = async () => {
            try {
                const response = await fetch('https://lottie.host/c0b80f4f-ad24-4488-81ca-b7e863d09f61/dmxchCuYhj.json', {
                    mode: 'cors',
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                await response.json();
            } catch (error) {
                console.error('Failed to preload Lottie animation:', error);
                setHasError(true);
            }
        };

        preloadLottieAnimation();

        // Set a timer to hide the splash screen after 3 seconds (3000ms)
        const timer = setTimeout(() => {
            setIsVisible(false);
            clearInterval(dotTimer); // Clear dot animation timer
        }, 1000); // Adjust the time as needed

        // Adjust lottie width based on window size
        const adjustLottieWidth = () => {
            if (window.innerWidth < 678) {
                setLottieWidth('50%');
            } else {
                setLottieWidth('10%');
            }
        };

        adjustLottieWidth();
        window.addEventListener('resize', adjustLottieWidth);

        // Clear the timer if the component unmounts
        return () => {
            clearTimeout(timer);
            clearInterval(dotTimer);
            window.removeEventListener('resize', adjustLottieWidth);
        };
    }, []);

    if (!isVisible) {
        return null; // Don't render the splash screen if it's no longer visible
    }

    return (
        <div className="splash-screen">
            {hasError ? (
                <h1 className='loading'> </h1>
            ) : (
                <lottie-player
                    src="https://lottie.host/c0b80f4f-ad24-4488-81ca-b7e863d09f61/dmxchCuYhj.json"
                    background="transparent"
                    speed="2"
                    style={{ width: lottieWidth, height: '100%' }}
                    className="loading-lottie"
                    loop
                    autoplay
                ></lottie-player>
            )}
            <h1 className='loading'>{loadingText}</h1>
        </div>
    );
}

export default SplashScreen;


// import React, { useState, useEffect } from 'react';
// import '@lottiefiles/lottie-player';
// import './SplashScreen.css';

// function SplashScreen() {
//     const [isVisible, setIsVisible] = useState(true);
//     const [hasError, setHasError] = useState(false);
//     const [loadingText, setLoadingText] = useState('Loading');

//     useEffect(() => {
//         // Timer to cycle the loading dots
//         const dotTimer = setInterval(() => {
//             setLoadingText(prev => {
//                 if (prev.length >= 10) return 'Loading';
//                 return prev + ' .';
//             });
//         }, 20); // Adjust dot animation speed here
 


//         // Preload the Lottie animation JSON
//         const preloadLottieAnimation = async () => {
//             try {
//                 const response = await fetch('https://lottie.host/c0b80f4f-ad24-4488-81ca-b7e863d09f61/dmxchCuYhj.json', {
//                     mode: 'cors',
//                 });
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 await response.json();
//             } catch (error) {
//                 console.error('Failed to preload Lottie animation:', error);
//                 setHasError(true);
//             }
//         };

//         preloadLottieAnimation();

//         // Set a timer to hide the splash screen after 3 seconds (3000ms)
//         const timer = setTimeout(() => {
//             setIsVisible(false);
//             clearInterval(dotTimer); // Clear dot animation timer
//         }, 1000); // Adjust the time as needed

//         // Clear the timer if the component unmounts
//         // return () => clearTimeout(timer);
//         return () => {
//             clearTimeout(timer);
//             clearInterval(dotTimer);
//         };

//     }, []);

//     if (!isVisible) {
//         return null; // Don't render the splash screen if it's no longer visible
//     }

//     return (
//         <div className="splash-screen">
//             {hasError ? (
//                 <h1 className='loading'> </h1>
//             ) : (
//                 <lottie-player
//                     src="https://lottie.host/c0b80f4f-ad24-4488-81ca-b7e863d09f61/dmxchCuYhj.json"
//                     background="transparent"
//                     speed="2"
//                     style={{ width: '10%', height: '100%' }}
//                     className="loading-lottie"
//                     loop
//                     autoplay
//                 ></lottie-player>
//             )}
//             <h1 className='loading'>{loadingText}</h1>
//         </div>
//     );
// }

// export default SplashScreen;