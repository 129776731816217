 
import "./AboutUsPage.css";
import { AboutHero, AboutValues, AboutCrew } from "./containers";
import FooterCharacters from "../../components/footer/FooterCharacters";
import FooterContacts from "../../components/footer/FooterContacts";
import FooterIconsAboutUs from "../../components/footer/FooterIconsAboutUs";
import FooterSec from "../../components/footer/FooterSec";
import { Header } from '../../components';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom'; 
import React, { useEffect, useRef, useState } from 'react';

// import DesignedFooter from "../../components/footer/DesignedFooter";

import i18n from "../../i18n";

function AboutUsPage({ analytics }) {
  const location = useLocation();
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  const [locale, setLocale] = useState(i18n.language);

  const section1Ref = useRef(null);
  const [scrollTriggerHeight, setScrollTriggerHeight] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (section1Ref.current) {  
      setScrollTriggerHeight(section1Ref.current.offsetHeight * 0.8); 
    }
  }, []);


  
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="home-page"
        >
          <section className="aboutUsSection1 bg-color-blue " ref={section1Ref}>
            <Header scrollTriggerHeight={scrollTriggerHeight}/>
            <AboutHero analytics={analytics} />
          </section>

          <section className="aboutUsSection2">
            <AboutValues />
          </section>

          <section className="aboutUsSection3">
            <AboutCrew />
          </section>

          <section className="aboutUsSection4">
            <FooterIconsAboutUs />
          </section>

          <section className="aboutUsSection5 bg-color-blue">
            <FooterSec />
          </section>

          <section className="aboutUsSection6">
            <FooterContacts />
          </section>

          {/* <section className="testing-footer">
          <DesignedFooter />
        </section> */}

          <section >
            <FooterCharacters />
          </section>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default AboutUsPage;
