import React, { useEffect, useState } from 'react';


function PlayjamHero({ analytics }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const headerSvg = windowWidth < 767
        ? "/images/playjam/Playjam_Header_mb.svg"
        : "/images/playjam/Playjam_Header_dk.svg";


    return (
        <>
            <div className="playjam-container-style">
                <img src={headerSvg} alt="Header SVG" style={{backgroundColor: 'white', transition: 'opacity 0.4s ease-in-out' }} /> 
            </div>

        </>
    );
}

export default PlayjamHero;