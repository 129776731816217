import React from 'react';
import UserData from './UserData';
import SubscriptionData from './SubscriptionData';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../header/Header';
import StepperComponent from './components/StepperComponent';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import DesignedFooter from '../footer/DesignedFooter';

const theme = createTheme();


const UserAccount = () => {
    const location = useLocation();

    // const { logout } = useAuth();

    // let navLinkClass = '';
    // let languageSelectorStyle = '';

    // if (location.pathname.includes('/account/user-data')) {
    //     // languageSelectorStyle = 'languageSelectorStyleClass';
    //     navLinkClass = 'playjam-detail-link-style'; // This class needs to be defined in your CSS
    // }


    return (
        <ThemeProvider theme={theme}>
            <div className="user-account">
                <Header
                    alwaysBlue={true}   
                    // navLinkClass={navLinkClass}
                    // languageSelectorStyle={languageSelectorStyle}
                />
                <div className='container d-flex flex-column flex-md-column justify-content-center p-5 gap-5'>
                    <div className='user-container'>
                        <UserData />
                    </div>
                    <div className='user-stepper'>
                        <StepperComponent />
                    </div>

                    <div className='subscription-container'>
                        <SubscriptionData />
                    </div>

                </div>

                <section >
                    {/* <DesignedFooter /> */}
                </section>



                {/* <SubscriptionData /> */}
            </div>
        </ThemeProvider>
    );
}

export default UserAccount;
