import "./HomeHero.css";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import React from "react";
import ButtonPanel from "./ButtonPanel";
import { logEvent } from "firebase/analytics";
import { useTranslation, Trans } from "react-i18next";
import { HashLink } from 'react-router-hash-link';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplineHome from "../../../components/spline/SplineHome";
import ThreeDScene from "./ThreeDScene";

gsap.registerPlugin(ScrollTrigger);

// Responsive web
// Mobile: Up to 480px
// Tablets: 481px to 1024px
// Desktops: 1025px to 1439px
// Large Screens: 1440px to 2559px
// Extra-Large Screens: 2560px and above


function HomeHero({ analytics }) {
  const { i18n } = useTranslation();
  const language = i18n.language.split('-')[0] || 'en';
  console.log("Simplified language code:", language);

  const letterRefs1 = useRef([]);
  const letterRefs2 = useRef([]);
  const letterRefs3 = useRef([]);
  const letterRefs4 = useRef([]);
  const letterRefs5 = useRef([]);
  const letterRefs6 = useRef([]);

  const titleRefs = useRef(null);
  const arBtnRefs = useRef([]);
  const famThreeRefs = useRef(null);
  const famThreeRefs2 = useRef(null);
  const initialPositions = useRef([]);  // Ref to store initial positions


  const tweenRefs = useRef([]);  // Ref to store the tween instances
  letterRefs1.current = [];
  letterRefs2.current = [];
  letterRefs3.current = [];
  letterRefs4.current = [];
  letterRefs5.current = [];
  letterRefs6.current = [];



  useLayoutEffect(() => {
    let mm = gsap.matchMedia();


    
    // Desktop screen
    mm.add("(min-width: 768px)", () => {
      const tl = gsap.timeline();

      const letterRefs = [
        letterRefs1.current,
        letterRefs2.current,
        letterRefs3.current,
        letterRefs4.current,
        letterRefs5.current,
        letterRefs6.current
      ]
      // Set random initial rotations
      letterRefs.forEach(refArray => {
        refArray.forEach(ref => {
          const yPercent = -600;
          const rotationZ = gsap.utils.random(-360, 360);
          gsap.set(ref, { yPercent, rotationZ });
          initialPositions.current.push({ ref, yPercent, rotationZ });  // Store initial positions

          // gsap.set(ref, { yPercent: -600, rotationZ: gsap.utils.random(-360, 360) });
        });
      });

      tl.set(titleRefs.current, { yPercent: 100 })
        .set(famThreeRefs.current, { yPercent: 100, opacity: 0 })
        .set(famThreeRefs2.current, { yPercent: 100, opacity: 0 })
        .set(arBtnRefs.current, { yPercent: 100 });

      letterRefs.forEach(refArray => {
        tl.to(refArray, {
          yPercent: -50,
          rotationZ: gsap.utils.random(-30, 30),
          duration: 3,
          scale: 1.1,
          stagger: 0.1,
          ease: "elastic.out(1, 0.4)"
        }, "<");
      })


      tl.to(titleRefs.current, {
        yPercent: -50,
        opacity: 1,
        duration: 2,
        ease: "elastic.out(1, 0.3)",
      }, "<")   // Start this animation 2.5 seconds earlier
        .to(arBtnRefs.current, {
          yPercent: -90,
          duration: 0.9,
          ease: "ease.out",
        }, "<")  // Start this animation at the same time as the previous one
        .to(famThreeRefs.current, {
          yPercent: 0,
          opacity: 1,
          duration: 1.2,
          ease: "elastic.out(1, 0.7)",
          delay: 0.5
        }, "<")
        .to(famThreeRefs2.current, {
          yPercent: 0,
          opacity: 1,
          duration: 1.2,
          ease: "elastic.out(1, 0.7)",
        }, "<");
    });





    // Mobile screen
    mm.add("(max-width: 767px)", () => {
      const titleHeight = titleRefs.current.clientHeight;

      const tl = gsap.timeline();
      tl.set([letterRefs1.current, letterRefs2.current, letterRefs3.current, letterRefs4.current, letterRefs5.current, letterRefs6.current], { yPercent: -600, rotationZ: -295 })
        .to(letterRefs1.current, {
          yPercent: -100 - titleHeight / 2,
          xPercent: 10,
          rotationZ: -25,
          scale: 1.2,
          duration: 3,
          ease: "elastic.out(1, 0.4)",
          stagger: 0
        }, "<")
        .to(letterRefs2.current, {
          yPercent: -60 - titleHeight / 2,
          xPercent: 10,
          rotationZ: 11,
          duration: 3,
          ease: "elastic.out(1, 0.4)",
          stagger: 0,
        }, "<")
        .to(letterRefs3.current, {
          yPercent: -80 - titleHeight / 2,
          xPercent: 15,
          rotationZ: -10,
          duration: 3,
          ease: "elastic.out(1, 0.4)",
          stagger: 0,
        }, "<")
        .to(letterRefs4.current, {
          yPercent: -10 - titleHeight / 2,
          // xPercent: 1600,
          rotationZ: 30,
          duration: 3,
          ease: "elastic.out(1, 0.4)",
          stagger: 0
        }, "<")
        .to(letterRefs5.current, {
          yPercent: -90 - titleHeight / 2,
          xPercent: 10,
          rotationZ: 15,
          duration: 3,
          ease: "elastic.out(1, 0.4)",
          stagger: 0,
        }, "<")
        .to(letterRefs6.current, {
          yPercent: -60 - titleHeight / 2,
          // xPercent: 1400,
          rotationZ: 0,
          duration: 3,
          ease: "elastic.out(1, 0.4)",
          stagger: 0,
        }, "<")
 
        .to(titleRefs.current, {
          yPercent: -50,
          opacity: 1,
          duration: 2,
          ease: "elastic.out(1, 0.3)",
        }, "<")

        .to(arBtnRefs.current, {
          yPercent: -150,
          duration: 0.9,
          scale: 1.5,
          ease: "ease.out",
        }, "<")


    });

    return () => mm.revert(); // Cleanup media queries when component unmounts




  }, [language]);


  const addToRefs = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el);
    }
  };


  const addToRefs2 = (el) => {
    if (el && !arBtnRefs.current.includes(el)) {
      arBtnRefs.current.push(el);
    }
  };

  const handleMouseEnter = (index, refArray) => {
    if (tweenRefs.current[index]) {
      tweenRefs.current[index].kill();
    }

    tweenRefs.current[index] = gsap.to(refArray[index], {
      duration: 3,
      x: gsap.utils.random(-50, 100),
      y: gsap.utils.random(-200, 50),
      rotation: 360,
      ease: "elastic.out(1, 0.6)",
    });
  };

  const handleMouseLeave = (index, refArray) => {
    if (tweenRefs.current[index]) {
      tweenRefs.current[index].kill();
    }

    const initial = initialPositions.current.find(pos => pos.ref === refArray[index]);
    if (initial) {
      tweenRefs.current[index] = gsap.to(refArray[index], {
        duration: 3,
        xPercent: initial.xPercent,
        rotationZ: initial.rotationZ,
        ease: "elastic.out(1, 0.3)",
      });
    }
 
  };

  return (
    <>
      <div className="home-background-style">
        <div className="wagzak-family-mainpage">
          <div className="pos" ref={famThreeRefs}>
            <SplineHome />
            {/* <ThreeDScene /> */}
          </div>

          <div className="container-wagzak-fam" >

            {/* first row */}
            <div className="row justify-content-center align-items-center p-2  ">
              {/* Mobile */}
              <div className="rows wagzak-letters-container ">
                <div className="col-2">
                  <img
                    src="/images/home/main_img_W.svg"
                    alt="letter w"
                    ref={el => addToRefs(el, letterRefs1)}
                    className="img-fluid wagzak-letters"
                    onMouseEnter={() => handleMouseEnter(0, letterRefs1.current)}
                    onMouseLeave={() => handleMouseLeave(0, letterRefs1.current)}

                  />
                </div>
                <div className="col-2">
                  <img

                    src="/images/home/main_img_a_03.svg"
                    ref={el => addToRefs(el, letterRefs3)}
                    alt="letter a"
                    className="img-fluid wagzak-letters"
                    onMouseEnter={() => handleMouseEnter(0, letterRefs3.current)}
                    onMouseLeave={() => handleMouseLeave(0, letterRefs3.current)}
                  />
                </div>
                <div className="col-2">
                  <img
                    src="/images/home/main_img_g_02.svg"
                    alt="letter g"
                    ref={el => addToRefs(el, letterRefs2)}
                    onMouseEnter={() => handleMouseEnter(0, letterRefs2.current)}
                    onMouseLeave={() => handleMouseLeave(0, letterRefs2.current)}
                    className="img-fluid wagzak-letters "
                  /></div>
                <div className="col-2">
                  <img
                    src="/images/home/main_img_Z_01.svg"
                    alt="letter z"
                    className="img-fluid wagzak-letters "
                    ref={el => addToRefs(el, letterRefs5)}
                    onMouseEnter={() => handleMouseEnter(0, letterRefs5.current)}
                    onMouseLeave={() => handleMouseLeave(0, letterRefs5.current)}
                  />
                </div>
                <div className="col-2">
                  <img
                    src="/images/home/main_img_a_02.svg"
                    alt="letter a"
                    className="img-fluid wagzak-letters "
                    ref={el => addToRefs(el, letterRefs6)}
                    onMouseEnter={() => handleMouseEnter(0, letterRefs6.current)}
                    onMouseLeave={() => handleMouseLeave(0, letterRefs6.current)}
                  />
                </div>

                <div className="col-2">
                  <img

                    src="/images/home/main_img_k_01.png"
                    alt="letter k"
                    ref={el => addToRefs(el, letterRefs4)}
                    className="img-fluid wagzak-letters "
                    onMouseEnter={() => handleMouseEnter(0, letterRefs4.current)}
                    onMouseLeave={() => handleMouseLeave(0, letterRefs4.current)}
                  />
                </div>





              </div>



              {/* Desktop */}
              {/* First row */}
              <div className="col-md-3 col-6 text-center order-md-1 ">
                <img
                  src="/images/home/main_img_W.svg"
                  alt="letter w"
                  ref={el => addToRefs(el, letterRefs1)}
                  className="img-fluid letters-mb"
                  onMouseEnter={() => handleMouseEnter(1, letterRefs1.current)}
                  onMouseLeave={() => handleMouseLeave(1, letterRefs1.current)}
                  style={{ maxWidth: "35%" }}
                />
              </div>

              <div className="col-md-3 col-6 text-center order-md-3">
                <img
                  src="/images/home/main_img_g_02.svg"
                  alt="letter g"
                  ref={el => addToRefs(el, letterRefs2)}
                  onMouseEnter={() => handleMouseEnter(1, letterRefs2.current)}
                  onMouseLeave={() => handleMouseLeave(1, letterRefs2.current)}
                  className="img-fluid letters-mb"
                  style={{ maxWidth: "25%" }}
                />
              </div>

              <div className="col-md-6 col-12 order-md-2 d-flex justify-content-center align-items-center text-center mask">
                <h1 className="section1-title-style" ref={titleRefs} >
                  <Trans
                    i18nKey="homePage.section1-title"
                    components={{ 1: <br /> }}
                  />
                </h1>
              </div>

            </div>


            {/* second row */}
            <div className="row row-cols-2 row-cols-md-3  justify-content-center align-items-center p-3 ">
              <div
                className="col-md-4 col-6 order-md-2 justify-content-center align-items-center text-center  mask"
                style={{ paddingBottom: "5%" }}
              >
                <a
                  onClick={() => {
                    logEvent(analytics, `Open_AR_ColoringPages`);
                  }}
                  href={language === 'kr' ? "https://circusar-contents.s3.ap-northeast-2.amazonaws.com/FTP/wagzak/Wagzak_coloring_print_KR.pdf" : "https://circusar-contents.s3.ap-northeast-2.amazonaws.com/FTP/wagzak/Wagzak_coloring_print_EN.pdf"}
                  target="_blank"
                >
                  <img
                    ref={addToRefs2}
                    className="arcoloring-mb img-fluid ar-coloring"
                    src={language === 'kr' ? "/images/home/pub_btn_coloring_kr.svg" : "/images/home/pub_btn_coloring_en.svg"}
                    alt="pub_btn_coloring_kr"
                    style={{ marginLeft: "40%" }}
                  />
                </a>
              </div>

              <div
                className="col-md-4 col-6 order-md-3 justify-content-center align-items-center text-center  mask"
                style={{ paddingBottom: "5%" }}
              >
                <HashLink to="#contentRequestSection" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                  <img
                    ref={addToRefs2}
                    className="arcoloring-mb img-fluid content-req"
                    src={language === 'kr' ? "/images/home/pub_btn_request_kr.svg" : "/images/home/pub_btn_request_en.svg"}
                    alt="pub_btn_request_kr"
                    style={{ marginRight: "40%" }}
                  />
                </HashLink>
              </div>

              <div className="col-md-2 col-6 order-md-1 text-center  ">
                <img
                  src="/images/home/main_img_a_02.svg"
                  ref={el => addToRefs(el, letterRefs3)}
                  alt="letter a"
                  className="img-fluid letters-mb"
                  onMouseEnter={() => handleMouseEnter(1, letterRefs3.current)}
                  onMouseLeave={() => handleMouseLeave(1, letterRefs3.current)}
                />
              </div>

              <div className="col-md-2 col-6 order-md-4 text-center">
                <img
                  src="/images/home/main_img_k_01.png"
                  alt="letter k"
                  ref={el => addToRefs(el, letterRefs4)}
                  className="img-fluid letters-mb"
                  onMouseEnter={() => handleMouseEnter(1, letterRefs4.current)}
                  onMouseLeave={() => handleMouseLeave(1, letterRefs4.current)}
                />
              </div>
            </div>

            {/* third row */}


            <div className="row row-cols-2 row-cols-md-3  justify-content-center align-items-center p-3 ">
              <div className="col-md-8 col-12 order-md-2  justify-content-center align-items-center text-center">

              </div>


              <div className="col-md-2 col-6 order-md-1 text-center  ">
                <img
                  src="/images/home/main_img_Z_01.svg"
                  alt="letter z"
                  className="img-fluid letters-mb"
                  ref={el => addToRefs(el, letterRefs5)}
                  onMouseEnter={() => handleMouseEnter(1, letterRefs5.current)}
                  onMouseLeave={() => handleMouseLeave(1, letterRefs5.current)}
                />
              </div>

              <div className="col-md-2 col-6 order-md-3 text-center">
                <img
                  src="/images/home/main_img_a_03.svg"
                  alt="letter a"
                  className="img-fluid letters-mb"
                  ref={el => addToRefs(el, letterRefs6)}
                  onMouseEnter={() => handleMouseEnter(1, letterRefs6.current)}
                  onMouseLeave={() => handleMouseLeave(1, letterRefs6.current)}
                />
              </div>

            </div>

          </div>

          <div className="second-cloud" ref={famThreeRefs2}></div>

        </div>


        <ButtonPanel analytics={analytics} />

      </div>


    </>
  );
}

export default HomeHero;


