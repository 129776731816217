import { Link, useLocation } from 'react-router-dom';
import i18n from '../../i18n';
import LocaleContext from '../../context/LocaleContext';
import { React, Suspense, useState, useEffect } from 'react';
import { Offcanvas } from 'bootstrap';
import './Header.css';
import CustomDropdown from './CustomDropdown';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

function Loading() {
  return <>Loading ..</>;
}

const firebaseConfig = {
  apiKey: 'AIzaSyA1YqO1JsEmtqueNZCSYKs8vg9_a-FWyOA',
  authDomain: 'wagzak-homepage.firebaseapp.com',
  projectId: 'wagzak-homepage',
  storageBucket: 'wagzak-homepage.appspot.com',
  messagingSenderId: '855242864757',
  appId: '1:855242864757:web:719064815b6fe65e56603c',
  measurementId: 'G-C5ZNRWJBB8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Header({ navLinkClass, alwaysBlue, scrollTriggerHeight = 50 }) {
  const currentLocation = useLocation();
  // const [locale, setLocale] = useState(i18n.language);
  const [locale, setLocale] = useState(() => {
    const lang = i18n.language.split('-')[0];
    return lang === 'en' || lang === 'kr' ? lang : 'en';
  });

  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(currentLocation.pathname);

  // languageSelectorStyle
  useEffect(() => {
    // This effect will be triggered after the component has mounted
    i18n.changeLanguage(locale);
    if (isOffcanvasOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // Or '', depending on what you use normally
    }

    // Cleanup to revert overflow when component unmounts or offcanvas closes
    return () => {
      document.body.style.overflow = 'auto'; // Or '', to reset to default
    };

  }, [locale, isOffcanvasOpen]);

  useEffect(() => {
    setActiveLink(currentLocation.pathname);
  }, [currentLocation]);

  const toggleOffcanvas = () => {
    setOffcanvasOpen(prevState => {
      const newState = !prevState;
      const bodyClass = 'offcanvas-open';
      if (newState) {
        document.body.classList.add(bodyClass);
      } else {
        document.body.classList.remove(bodyClass);
      }
      return newState;
    });
  };


  // checking fixed header
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       document.querySelector('.navbar').classList.add('navbar-scrolled');
  //     } else {
  //       document.querySelector('.navbar').classList.remove('navbar-scrolled');
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);
   // checking fixed header
   useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollTriggerHeight) {
        document.querySelector('.navbar').classList.add('navbar-scrolled');
      } else {
        document.querySelector('.navbar').classList.remove('navbar-scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollTriggerHeight]);



  const handleLinkClick = (path) => {

    setActiveLink(path);
    let eventName;

    if (path === "/") {
      eventName = "Home_Page";
    } else if (path === "/playjam") {
      eventName = "Playjam_Page";
    } else if (path === "/characters") {
      eventName = "Characters_Page";
    } else if (path === "/aboutus") {
      eventName = "AboutUs_Page";
    } else if (path === "/wagzakapp") {
      eventName = "Contents_Page";
    } else {
      eventName = `${path.substring(1)}_Page`;
    }

    logEvent(analytics, eventName);
  };


  return (
    // <nav className="navbar navbar-expand-lg  "   >
    <nav className={`navbar navbar-expand-lg ${alwaysBlue ? 'always-blue' : ''}`}>
      <div className="container">
        <Link
          to="/"
          className="navbar-brand"
          href="index.html"
          onClick={() => handleLinkClick("/")}
        >
          {' '}
          <img
            src="/images/logo/wagzaklogo.svg"
            alt="wagzak logo"
            className="logo_btn_mb img-fluid"
          />
        </Link>

        {/* toggler */}
        <button
          className="navbar-toggler shadow-none border-0"
          type="button"
          onClick={toggleOffcanvas}
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* sidebar */}
        <div
          className="sidebar offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          {/* sidebar header */}
          <div className="offcanvas-header text-black">
            <Link to="/" onClick={() => handleLinkClick("/")}>
              <img
                src="/images/logo/wagzaklogo.svg"
                alt="wagzak logo"
                className="logo_btn_mb img-fluid"
              />
            </Link>

            <img
              src="/images/header/close_btn.svg"
              data-bs-dismiss="offcanvas"
              height={35}
            ></img>
          </div>

          {/* sidebar body */}
          {/* overflowY: 'auto' */}
          <div className="offcanvas-body d-flex flex-column flex-lg-row p-4 " style={{ maxHeight: '100vh' }}>
            <ul className="navbar-nav justify-content-center align-items-center flex-grow-1 ">
              <li className="nav-item mx-2">
                <Link
                  to="/wagzakapp"
                  // className={`nav-link header-text-style ${navLinkClass}`}
                  className={`nav-link header-text-style ${navLinkClass} ${activeLink === '/wagzakapp' ? 'active' : ''}`}
                  onClick={() => handleLinkClick("/wagzakapp")}
                >
                  Wagzak App
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  to="/playjam"
                  className={`nav-link header-text-style ${navLinkClass} ${activeLink === '/playjam' ? 'active' : ''}`}
                  onClick={() => handleLinkClick("/playjam")}
                >
                  Playjam
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  to="/characters"
                  className={`nav-link header-text-style ${navLinkClass} ${activeLink === '/characters' ? 'active' : ''}`}
                  onClick={() => handleLinkClick("/characters")}
                >
                  Characters
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  to="/aboutus"
                  className={`nav-link header-text-style ${navLinkClass} ${activeLink === '/aboutus' ? 'active' : ''}`}
                  onClick={() => handleLinkClick("/aboutus")}
                >
                  About us
                </Link>
              </li>
              {/* <li className="nav-item mx-2">
                <Link
                  to="/subscription"
                  className={`nav-link header-text-style ${navLinkClass} ${activeLink === '/subscription' ? 'active' : ''}`}
                  onClick={() => handleLinkClick("/subscription")}
                >
                  Subscription
                </Link>
              </li> */}
            </ul>



            <div className="language-btn ml-auto d-flex justify-content-center flex-row flex-lg-row">
              <LocaleContext.Provider value={{ locale, setLocale }}>
                <Suspense fallback={<Loading />}>
                  <CustomDropdown />
                </Suspense>
              </LocaleContext.Provider>
            </div>


            {/* user account icon */}
            {/* <div className="user-btn d-flex justify-content-center">
              <a
                // href="/account/user-data"
                href="#"
                onClick={() => {
                  logEvent(analytics, `SignIn`);
                }}
              >
                <img
                  src="/images/userAccount/pub_btn_account.svg"
                  className="eng_btn img-fluid "
                   
                />
              </a>
              </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
