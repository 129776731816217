
import { CharactersHero, CharactersFamily, CharactersFriends } from './containers';
import './CharactersPage.css';
import { useLocation } from "react-router-dom";
import { Header } from '../../components';
import FooterContacts from "../../components/footer/FooterContacts";
import FooterIconsTwo from "../../components/footer/FooterIconsTwo";
import FooterSec from "../../components/footer/FooterSec";
import FooterSubscribe2 from "../../components/footer/FooterSubscribe2";
import { motion, AnimatePresence } from 'framer-motion';
import FooterCharacters from "../../components/footer/FooterCharacters";
import React, { useEffect, useRef, useState } from 'react';
 

function CharactersPage({ analytics }) {
  const location = useLocation();
  const section1Ref = useRef(null);
  const [scrollTriggerHeight, setScrollTriggerHeight] = useState(0);


  useEffect(() => {
    window.scrollTo(0, 0);
    if (section1Ref.current) {
      setScrollTriggerHeight(section1Ref.current.offsetHeight * 0.8);
    }
  }, []);

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <section className='characters-section1 bg-color-blue' ref={section1Ref}>
            <Header scrollTriggerHeight={scrollTriggerHeight} />
            <CharactersHero analytics={analytics} />
          </section > 
          <section className='characters-section2'>
            <CharactersFamily />
          </section>

          <section  >
            <CharactersFriends />
          </section>

          {/* footer */}
          <section>
            <FooterSubscribe2 />
          </section>

          <section>
            <FooterIconsTwo />
          </section>

          <section className="bg-color-green">
            <FooterSec />
          </section>

          <section>
            <FooterContacts />
          </section>


          <section  >
            <FooterCharacters />
          </section>
        </motion.div>
      </AnimatePresence >
    </>
  );
}

export default CharactersPage;