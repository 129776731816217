import React, { useEffect } from 'react';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity'; 

function HomeLottieAnimationSecond() {
    useEffect(() => {
        const firstPlayer = document.getElementById("secondLottie");

        // Listen for player ready event
        firstPlayer.addEventListener("ready", () => {
            create({
                player: "#secondLottie",
                mode: "scroll",
                actions: [{ visibility: [0, 0.35], type: "stop", frames: [0] }, { visibility: [0.35, 0.45], type: "seek", frames: [0, 61] }, { visibility: [0.45, 1.0], type: "loop", frames: [61, 120] }]
            });
        });

        // window.addEventListener("scroll", () => {
        //     console.log("Scrolled!");
        // });


        // Select all Lottie players
        const elements = document.getElementsByTagName('lottie-player');

        // Add event listeners for 'play' event
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('play', (event) => {
                if (event.target.shadowRoot && event.target.shadowRoot.querySelector('svg')) {
                    event.target.shadowRoot.querySelector('svg').style.transform = '';
                }
            });

            elements[i].play();
        }
    }, []);

    return (
        <div className='d-flex justify-content-end content-item-right'>
            <lottie-player
                id="secondLottie"
                // src="https://lottie.host/f315fa56-1531-4319-8276-867edbc96a4e/cceEoIQqdG.json"
                src="https://lottie.host/b192118e-09a7-48cf-858d-09360f7a2e21/8gWfVJZrzc.json"
                background="transparent"
                speed="1"
                style={{
                    width: '100%', 
                    right: '0', margin: '0',
                    padding: '0'
                }}
                loop
                className="lottie-animation"
                autoplay
            ></lottie-player>
        </div>
    );
}

export default HomeLottieAnimationSecond;
