import React from 'react'; 
import './FooterSec.css';

function FooterSec() {
    return (
        <div className='footersec-style'> 
             <object data="/images/footer/main_footer_double_cloud.svg" type="image/svg+xml"/>
        </div>
    );

} 
 export default FooterSec;