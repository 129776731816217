import './WagzakAppFeatures.css';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';


function WagzakAppFeatures() {

    const { t } = useTranslation();

    return ( 
            <div className='container m-top-5 m-bottom-5 '> 
                <div className="row row-cols-1 row-cols-md-2 g-5">
                    <div className="col">
                        <div className="card border-0">
                            <img src="/images/wagzakApp/wagzakApp_img_category_01.png" className="card-img-top rounded" alt="content category" />
                            <div className="card-body text-center">
                                <h5 className="card-title content-section3-title"><Trans i18nKey="wagzakAppPage.card1-title" /></h5>
                                <p className="card-text content-section3-text">
                                    
                                    <Trans i18nKey="wagzakAppPage.card1-text" components={{ 1: <br /> }} />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-0">
                            <img src="/images/wagzakApp/wagzakApp_img_category_02.png" className="card-img-top rounded" alt="content category" />
                            <div className="card-body text-center">
                                <h5 className="card-title content-section3-title"><Trans i18nKey="wagzakAppPage.card2-title" /></h5>
                                <p className="card-text content-section3-text">
                                    <Trans i18nKey="wagzakAppPage.card2-text" components={{ 1: <br /> }} />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-0">
                            <img src="/images/wagzakApp/wagzakApp_img_category_03.png" className="card-img-top rounded" alt="content category" />
                            <div className="card-body text-center">
                                <h5 className="card-title content-section3-title"><Trans i18nKey="wagzakAppPage.card3-title" /></h5>
                                <p className="card-text content-section3-text">
                                    <Trans i18nKey="wagzakAppPage.card3-text" components={{ 1: <br /> }} />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-0">
                            <img src="/images/wagzakApp/wagzakApp_img_category_04.png" className="card-img-top rounded" alt="content category" />
                            <div className="card-body text-center">
                                <h5 className="card-title content-section3-title"><Trans i18nKey="wagzakAppPage.card4-title" /></h5>
                                <p className="card-text content-section3-text">
                                    <Trans i18nKey="wagzakAppPage.card4-text" components={{ 1: <br /> }} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        
    );
}

export default WagzakAppFeatures;
