 import { useTranslation, Trans } from 'react-i18next';
 
function CharactersFriends() {
    const { t } = useTranslation();
    return (
        <>
 
                <div className='container flex-container-column m-top-3 m-bottom-10 '> 
                    <div className='row g-2'>
                        <div className='col-2 col-md-2'>  </div>
                        <div className='col-8 col-md-8 d-flex justify-content-center' style={{whiteSpace: "nowrap"}}>
                            <h5 className='content-main-title-ef font-color-red' ><Trans i18nKey="charactersPage.wagzak-friends" /></h5>
                        </div>
                        <div className='col-2 col-md-2'>  </div> 
                    </div>


                    <div className="row row-cols-2 row-cols-md-4 g-5 p-15 text-center p-3">
                        <div className="col" >
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Koko.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Koko</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Lemon.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">lemon</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Rubi.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Rubi</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Blue.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Blue</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col" >
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Okto.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Okto</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Tokki.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Tokki</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Kiri.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Kiri</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Panpa.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Panpa</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col" >
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Chub.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Chub</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Dotori.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Dotori</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Havi.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Havi</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 border-0">
                                <img src="/images/characters/wagzakFriends/character_img_Bada.svg" className="card-img-top h-100" alt="..." style={{ width: '100%' }} />
                                <div className="card-body">
                                    <h5 className="card-title">Bada</h5>
                                </div>
                            </div>
                        </div>
                    </div>

               
            </div>

        </>
    );
}

export default CharactersFriends;