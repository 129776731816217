import React, { useState, useContext, useEffect, useRef } from 'react';
import LocaleContext from '../../context/LocaleContext';
import './Header.css';

const CustomDropdown = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = [
    { value: 'en', label: 'EN' },
    { value: 'kr', label: 'KR' }
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setLocale(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="int_select" ref={dropdownRef}>
      <div className={`select_box_title ${isOpen ? 'active' : ''}`} onClick={toggleDropdown}>
        {locale.toUpperCase()}
      </div>
      {isOpen && (
        <div className="select_box_list show">
          <div className="dim" onClick={() => setIsOpen(false)}></div>
          <ul>
            {options.map((option) => (
              <li key={option.value}>
                <a href="#" onClick={() => handleOptionClick(option.value)}>
                  {option.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
