
import React, { useEffect, useRef, useState } from 'react';
import { PlayjamHero, PlayjamOverview, PlayjamContentDetail } from "./containers";
import './PlayjamPage.css'; 
import FooterContacts from "../../components/footer/FooterContacts";
import FooterIconsTwo from "../../components/footer/FooterIconsTwo";
import FooterSec from "../../components/footer/FooterSec";
import FooterSubscribe from "../../components/footer/EmailSubscribe";
import { Header } from '../../components';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from "react-router-dom";
import FooterCharacters from "../../components/footer/FooterCharacters";


function PlayjamPage({ analytics }) {
  const location = useLocation();
  const section1Ref = useRef(null);
  const [scrollTriggerHeight, setScrollTriggerHeight] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (section1Ref.current) {
      setScrollTriggerHeight(section1Ref.current.offsetHeight * 0.8); 
    }
  }, []);

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }} 
        >
          <section className='playjam-section1 bg-color-blue' id="beginning-of-playjam" ref={section1Ref}>
            <Header scrollTriggerHeight={scrollTriggerHeight} />
            <PlayjamHero analytics={analytics} />
          </section >

          <section className='playjam-section2'>
            <PlayjamOverview />
          </section>

          <section >
            <FooterSubscribe analytics={analytics} />
          </section>

          <section >
            <FooterIconsTwo />
          </section>

          <section className="bg-color-green">
            <FooterSec />
          </section>

          <section >
            <FooterContacts />
          </section>


          <section className="playjamSection7">
              <FooterCharacters />
            </section>

        </motion.div>
      </AnimatePresence>
    </>

  );
}

export default PlayjamPage;