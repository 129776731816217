 
import './AboutHero.css';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next'; 

function AboutHero({ analytics }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headerSvg = windowWidth < 767
    ? "/images/aboutUs/About-us_img_Header_mobile-version.gif"
    : "/images/aboutUs/About-us_img_Header_PC-version.gif";

  return (
    <div className="aboutus-container-style">
      <div className='container content-main-title-ef'> 
          <h1 className='aboutus-header-title  display-5'>
            <Trans i18nKey="aboutUsPage.section1-title" components={{ 1: <br /> }} />
          </h1>
      </div>

      <img src={headerSvg} alt="Header SVG"  style={{backgroundColor: 'white', transition: 'opacity 0.4s ease-in-out' }}/>
    </div>
  );
}

export default AboutHero;
