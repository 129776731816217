import './App.css';
import './assets/styles/color.css';
import './assets/styles/typography.css';
import './assets/styles/layout.css';
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { AuthProvider } from './components/auth/AuthProvider';
// import { FooterCharacters, FooterContacts, FooterIconsTwo, FooterSec, FooterSubscribe2 } from './components';
import { FooterCharacters } from './components';
import ButtonPanel from './pages/home/containers/ButtonPanel';
import SplashScreen from './components/splashScreen/SplashScreen';
import AnimatedRoutes from './routes/AnimatedRoutes';

function App({ analytics }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const loadResources = async () => {
      // Simulate loading time or actual resource loading
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setLoading(false);
    };

    loadResources();
  }, []);


  return (
    <AuthProvider>
      <AnimatePresence mode="wait">
        {loading ? (
          <SplashScreen />
        ) : (
          <motion.div
            key={location.pathname}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >

            <div className="wrapper">
              <AnimatedRoutes />
              <ButtonPanel analytics={analytics} />
            </div>
          </motion.div>

        )}

       

      </AnimatePresence>
    </AuthProvider>

  );
}

export default App;



 {/* <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }} 
        >
        
      <div className="wrapper">
        <AnimatedRoutes />
        <ButtonPanel analytics={analytics} />
      </div>
     }
      </motion.div> */}
