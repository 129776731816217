import React, { useState } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Custom from './Custom';

const MailchimpForm = () => {
  const postUrl = process.env.REACT_APP_MAILCHIMP_API_URL;

  return (
    <div >
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <Custom
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}

          />
        )}
      />
    </div>
  );
};
export default MailchimpForm;