import FooterCharacters from '../../../components/footer/FooterCharacters';
import FooterContacts from '../../../components/footer/FooterContacts';
import FooterIconsTwo from '../../../components/footer/FooterIconsTwo';
import FooterSec from '../../../components/footer/FooterSec';
import FooterSubscribe from '../../../components/footer/EmailSubscribe';
import Header from '../../../components/header/Header';
import '../PlayjamPage.css';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox'; 
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function PlayjamContentDetail({ analytics }) {
  const { contentID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0] || 'en';

  const [contentDetail, setContentDetail] = useState(null);
  const [englishContentDetail, setEnglishContentDetail] = useState(null);
  const [koreanContentDetail, setKoreanContentDetail] = useState(null);

  const [isKoreanPdfChecked, setIsKoreanPdfChecked] = useState(false);
  const [isEnglishPdfChecked, setIsEnglishPdfChecked] = useState(false);

  let navLinkClass = '';  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const fetchContentDetail = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/contents/detail/${contentID}`
        );

        setContentDetail(response.data);
        setEnglishContentDetail(response.data.details.englishTranslation);
        setKoreanContentDetail(response.data.details.koreanTranslation);

      } catch (error) {
        console.error('Failed to fetch content details', error);
      }
    };

    fetchContentDetail();
  }, []);

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the history stack
  };

  // Handler for when the checkbox is clicked
  const handleKoreanPdfCheckboxChange = (event) => {
    setIsKoreanPdfChecked(event.target.checked);
  }

  const handleEnglishPdfCheckboxChange = (event) => {
    setIsEnglishPdfChecked(event.target.checked);
  };

  const handleDownload = () => {
    if (isKoreanPdfChecked) {
      window.open(koreanContentDetail.contentPdf, '_black');
    }
    if (isEnglishPdfChecked) {
      window.open(englishContentDetail.contentPdf, '_blank');
    }
    if (!isKoreanPdfChecked && !isEnglishPdfChecked) {
      // No checkbox selected, download the PDF based on the current page language
      const url = language === 'kr' ? koreanContentDetail.contentPdf : englishContentDetail.contentPdf;
      window.open(url, '_blank');
    }
  }


  return (
    <>


      {contentDetail && (
        // <motion.div
        //   initial={{ opacity: 0, transition: { duration: 0.10 } }}
        //   animate={{ opacity: 1 }}
        //   exit={{ opacity: 0, transition: { duration: 0.10 } }}
        // >
        <>
          <section className="" id="beginning-of-playjam">
            <Header 
              alwaysBlue={true}
            />
          </section>

          <div className="container text-center playjam-detail">

            {language === 'en' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '900px',
                    maxWidth: '100%',
                    margin: '0 auto',
                    padding: '5%',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <h2 className="playjamSection2-title-style p-3">
                      {englishContentDetail.contentTitle}
                    </h2>
                  </div>
                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <p className="playjamSection2-title-style-p ">
                      {englishContentDetail.contentDescription}
                    </p>
                  </div>
                  <div className="image-container" style={{ position: 'relative' }}>
                    <img
                      src={englishContentDetail.contentImage}
                      alt={englishContentDetail.contentTitle}
                      className="rounded"
                      style={{
                        width: '820px',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                      }}
                    />
                    <a
                      href={englishContentDetail.contentPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pdf-a-style"
                      style={{
                        position: 'absolute', // Absolute positioning for the button
                        bottom: '25px', // Positioned at the bottom-right corner
                        right: '30px',
                        textDecoration: 'none',
                      }}
                    >

                      <img
                        src="/images/playjam/playjam_pdf_download.png" // Add the actual path to your download button image here
                        alt="Download"
                        className="pdf-btn-detail-img"
                        style={{
                          maxWidth: '60px', // Adjust the size as needed
                          maxHeight: '100px',
                        }}
                      />
                    </a>
                  </div>

                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <p className="playjamSection2-title-style-p mt-5 ">
                      {englishContentDetail.contentDetailDescription}
                    </p>
                  </div>

                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <h2 className="playjamSection2-title-style p-3">Tips</h2>
                  </div>


                  {/* I should bring Tips here! */}
                  <div
                    style={{ maxWidth: '100%', width: '700px', textAlign: 'left' }}
                  >
                    <ul className="playjamSection2-title-style-p p-3">
                      <li> <Trans i18nKey="playjamPage.section2-detail-tips1" /></li>
                      <li> <Trans i18nKey="playjamPage.section2-detail-tips2" /></li>
                    </ul>
                  </div>


                </div>

                {englishContentDetail.contentPdf && (
                  <>
                    <div className='playjam-pdf-checkbox'   >
                      <Checkbox
                        name="koreanPdfCheckbox"
                        checked={isKoreanPdfChecked}
                        onChange={handleKoreanPdfCheckboxChange}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        style={{ color: '#47CFA7' }} />
                      <label htmlFor="koreanPdfCheckbox">Korean</label>


                      <Checkbox
                        name="englishPdfCheckbox"
                        checked={isEnglishPdfChecked}
                        onChange={handleEnglishPdfCheckboxChange}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        style={{ color: '#47CFA7', marginLeft: "20px" }} />
                      <label htmlFor="englishPdfCheckbox">English</label>

                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '5%',
                      }}
                    >
                      <button
                        className="playjam-btn-download"
                        onClick={handleDownload}
                        style={{ textDecoration: 'none' }}
                      >
                        Download Playjam
                      </button>
                    </div>

                  </>
                )}

              </>)}

              

            {language === 'kr' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '900px',
                    maxWidth: '100%',
                    margin: '0 auto',
                    padding: '5%',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <h2 className="playjamSection2-title-style p-3">
                      {koreanContentDetail.contentTitle}
                    </h2>
                  </div>
                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <p className="playjamSection2-title-style-p ">
                      {koreanContentDetail.contentDescription}
                    </p>
                  </div>
                  <div className="image-container" style={{ position: 'relative' }}>
                    <img
                      src={koreanContentDetail.contentImage}
                      alt={koreanContentDetail.contentTitle}
                      className="rounded"
                      style={{
                        width: '820px',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                      }}
                    />
                    <a
                      href={koreanContentDetail.contentPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pdf-a-style"
                      style={{
                        position: 'absolute', // Absolute positioning for the button
                        bottom: '25px', // Positioned at the bottom-right corner
                        right: '30px',
                        textDecoration: 'none',
                      }}
                    >

                      <img
                        src="/images/playjam/playjam_pdf_download.png" // Add the actual path to your download button image here
                        alt="Download"
                        className="pdf-btn-detail-img"
                        style={{
                          maxWidth: '60px', // Adjust the size as needed
                          maxHeight: '100px',
                        }}
                      />
                    </a>
                  </div>

                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <p className="playjamSection2-title-style-p mt-5 ">
                      {koreanContentDetail.contentDetailDescription}
                    </p>
                  </div>

                  <div
                    style={{
                      maxWidth: '100%',
                      width: '700px',
                      textAlign: 'center',
                    }}
                  >
                    <h2 className="playjamSection2-title-style p-3">Tips</h2>
                  </div>
                  <div
                    style={{ maxWidth: '100%', width: '700px', textAlign: 'left' }}
                  >
                    <ul className="playjamSection2-title-style-p p-3">
                      <li> <Trans i18nKey="playjamPage.section2-detail-tips1" /></li>
                      <li> <Trans i18nKey="playjamPage.section2-detail-tips2" /></li>
                    </ul>
                  </div>
                </div>
                {koreanContentDetail.contentPdf && (
                  //if chekcbox triggered? (print triggered pdf file forexample: if english and korean was triggered print both of them!):(print default{englishContentDetail.contentPdf} pdf file )
                  <>
                    <div className='playjam-pdf-checkbox'>
                    <Checkbox
                        name="koreanPdfCheckbox"
                        checked={isKoreanPdfChecked}
                        onChange={handleKoreanPdfCheckboxChange}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        style={{ color: '#47CFA7' }} />
                      <label htmlFor="koreanPdfCheckbox">한글</label>


                      <Checkbox
                        name="englishPdfCheckbox"
                        checked={isEnglishPdfChecked}
                        onChange={handleEnglishPdfCheckboxChange}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        style={{ color: '#47CFA7', marginLeft: "20px" }} />
                      <label htmlFor="englishPdfCheckbox">영문</label>

                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '5%',
                      }}
                    >
                      <button
                        className="playjam-btn-download"
                        onClick={handleDownload}
                        style={{ textDecoration: 'none' }}
                      >
                        플레이젬 다운로드
                      </button>
                    </div>

                  </>
                )}

              </>)}


            {/* Remained "all" contents */}

            <div className="row row-cols-1 row-cols-md-2 g-5 p-5">
              {contentDetail.nextAll.map((content, index) => (

                <div key={index} className="col">
                  <div
                    className="card h-100 border-0"
                    style={{ width: '100%', height: '450px' }}
                  >

                    {language === 'en' && (
                      <>
                        <div
                          className="image-container"
                          style={{ position: 'relative' }}
                        >
                          <Link to={`/playjam/all/${content.contentID}`} style={{ textDecoration: "none", color: "inherit" }}>
                            <img
                              src={content.englishTranslation.contentImage}
                              alt={content.englishTranslation.contentTitle}
                              className="card-img-top rounded"
                              style={{
                                maxWidth: '100%',
                                width: '567px',
                                maxHeight: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                              }}
                            />
                          </Link>
                          <a
                            href={content.englishTranslation.contentPdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="pdf-btn-detail-img-card"
                            style={{
                              position: 'absolute', // Absolute positioning for the button
                              bottom: '20px', // Positioned at the bottom-right corner
                              right: '25px',
                              textDecoration: 'none',
                            }}
                          >

                            <img
                              src="/images/playjam/playjam_pdf_download.png" // Add the actual path to your download button image here
                              alt="Download"
                              style={{
                                maxWidth: '40px', // Adjust the size as needed
                                maxHeight: '40px',
                              }}
                            />
                          </a>
                        </div>
                        <div className="card-body">
                          <h3 className="card-title playjam-section2-title">
                            {content.englishTranslation.contentTitle}
                          </h3>
                          <p className="card-text playjam-section2-text">
                            {content.englishTranslation.contentDescription}
                          </p>
                        </div>
                      </>)}



                    {language === 'kr' && (
                      <>
                        <div
                          className="image-container"
                          style={{ position: 'relative' }}
                        >
                          <Link to={`/playjam/all/${content.contentID}`} style={{ textDecoration: "none", color: "inherit" }}>
                            <img
                              src={content.koreanTranslation.contentImage}
                              alt={content.koreanTranslation.contentTitle}
                              className="card-img-top rounded"
                              style={{
                                maxWidth: '100%',
                                width: '567px',
                                maxHeight: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                              }}
                            />
                          </Link>
                          <a
                            href={content.koreanTranslation.contentPdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="pdf-btn-detail-img-card"
                            style={{
                              position: 'absolute', // Absolute positioning for the button
                              bottom: '20px', // Positioned at the bottom-right corner
                              right: '25px',
                              textDecoration: 'none',
                            }}
                          >

                            <img
                              src="/images/playjam/playjam_pdf_download.png" // Add the actual path to your download button image here
                              alt="Download"
                              style={{
                                maxWidth: '50px', // Adjust the size as needed
                                maxHeight: '50px',
                              }}
                            />
                          </a>
                        </div>
                        <div className="card-body">
                          <h3 className="card-title playjam-section2-title">
                            {content.koreanTranslation.contentTitle}
                          </h3>
                          <p className="card-text playjam-section2-text">
                            {content.koreanTranslation.contentDescription}
                          </p>
                        </div>
                      </>)}
                  </div>

                </div>
              ))}
            </div>
 

          </div>

          <section className="playjamPageSection2-1">
            <FooterSubscribe analytics={analytics} />
          </section>

          <section className="playjamSection3">
            <FooterIconsTwo />
          </section>

          <section className="playjamSection5 bg-color-green">
            <FooterSec />
          </section>

          <section className="playjamSection6">
            <FooterContacts />
          </section>

          <section className="playjamSection7">
            <FooterCharacters />
          </section>
          </>
        // </motion.div>
        
         
      )}
    </>
  );
}

export default PlayjamContentDetail;
