import React, { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './Custom.scss'
import { useTranslation, Trans } from 'react-i18next';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics"; 
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA1YqO1JsEmtqueNZCSYKs8vg9_a-FWyOA",
    authDomain: "wagzak-homepage.firebaseapp.com",
    projectId: "wagzak-homepage",
    storageBucket: "wagzak-homepage.appspot.com",
    messagingSenderId: "855242864757",
    appId: "1:855242864757:web:719064815b6fe65e56603c",
    measurementId: "G-C5ZNRWJBB8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Custom = ({ status, message, onValidated }) => {

    const { t } = useTranslation();

    useEffect(() => {
        if (status === 'success')
            clearFields();
        logEvent(analytics, 'Email_Subscribe');

    }, [status]);
    const clearFields = () => {
        setEmail('');
    };
    const [email, setEmail] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        email && email.indexOf('@') > -1 && onValidated({ EMAIL: email });
    };
    return (
        <form className="mc__form-container" id="myform" onSubmit={handleSubmit}> 
            {status === 'sending' && <div className="mc__alert mc__alert--sending">sending...</div>}
            {status === 'error' && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === 'success' && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            <div className="d-flex justify-content-center align-items-center  ">
                <div className="custom-input text-center">
                    <input
                        type="email"
                        value={email}
                        id='myid'
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button className="custom-button" type="submit" onClick={handleSubmit}>
                        Subscribe
                    </button>
                </div>
            </div>
        </form>
    );
};
export default Custom;