
import React, { useEffect, useRef, useState } from 'react';
import { HomeHero, HomePlayjam, HomeWorld, HomePlayground } from "./containers";
import './HomePage.css';
import HomeLottieAnimation from "../../components/lottieAnimationFiles/HomeLottieAnimation";

import ContentRequest from "../../components/mailchimpServices/ContentRequest";
import FooterContacts from "../../components/footer/FooterContacts";
import FooterIcons from "../../components/footer/FooterIcons";
import FooterSec from "../../components/footer/FooterSec";
import FooterCharacters from "../../components/footer/FooterCharacters";
import AnimatedFooter from "../../components/footer/AnimatedFooter";
import FooterPlayground from "../../components/footer/FooterPlayground";
import { Header } from '../../components';
// import { CharactersFamily } from '../characters/containers';


function HomePage({ analytics }) {
  const section1Ref = useRef(null);
  const [scrollTriggerHeight, setScrollTriggerHeight] = useState(0);


  useEffect(() => {
    window.scrollTo(0, 0);
    if (section1Ref.current) {
      setScrollTriggerHeight(section1Ref.current.offsetHeight * 0.8);
    }
  }, []);

  return (
    <>
      {/* <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="home-page"
        >  */}
      <div className='home-page' >
        <section className='section1' ref={section1Ref}>
          <Header scrollTriggerHeight={scrollTriggerHeight} />
          <HomeHero analytics={analytics} />
        </section >

        <section className='section2'>
          <HomeLottieAnimation />
        </section>

        {/* <section>
          <CharactersFamily />
        </section> */}


        <section className='section3'>
          <div className='container'>
            <HomePlayjam />
          </div>
        </section>

        <section className='section4'>
          <div className='container'>
            <HomeWorld />
          </div>
        </section>

        <section className='section5'>
          <HomePlayground />
        </section>


        <section className='section5-1' id="contentRequestSection">
          <ContentRequest analytics={analytics} />
        </section>


        <section className='section6'>
          <FooterIcons />
          {/* <AnimatedFooter /> */}
        </section>

        {/* <FooterPlayground /> */}

        <section className="section8">
          <FooterSec />
        </section>

        <section className='section9'>
          <FooterContacts />
        </section>


        <section className='section10'>
          <FooterCharacters />
        </section>
        {/* <section className='section10'> */}
        {/* <FooterCharacters /> */}
        {/* </section> */}



        {/* </motion.div>
      </AnimatePresence> */}
      </div>
    </>
  );
}

export default HomePage;
