import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const TitleAnimation = ({ text, className }) => {
  const titleRef = useRef(null);

  useLayoutEffect(() => {
    gsap.set(titleRef.current, { scale: 2 });

    gsap.fromTo(titleRef.current, 
        { scale: 0.3 },
      {
        scale: 1,
        duration: 0.9,
        ease: 'power1.inOut',
        scrollTrigger: {
          trigger: titleRef.current,
          start: 'top 75%',
          end: 'bottom 25%',
          toggleActions: 'play none none none',
        }
      }
    );
  }, []);

  return (
    <h1 ref={titleRef} className={className}>
      {text}
    </h1>
  );
};

export default TitleAnimation;
