import React from 'react';
import "../../pages/home/containers/HomeHero.css"

const SplineHome = () => {
    return (
        <div className="spline-iframe-container">
            <iframe
                title="External Iframe"
                src="https://my.spline.design/testlookatwagzak-fa4f2d22708e7678e7891a051aa2a2c4/"
                width="100%"
                height="100%"
                className="spline-iframe"
            />
        </div>



    );
};

export default SplineHome;

{/* <div className="spline-iframe-container">
            <spline-viewer
                url="https://my.spline.design/testlookatwagzak-fa4f2d22708e7678e7891a051aa2a2c4/"
                style={{ width: '100%', height: '100%' }}
                className="spline-iframe"
            />
        </div> */}