import "./BeamingButton.css";
import { FiPlus } from "react-icons/fi";

const BeamingButton = () => {
  return (
    <div className="btn-holder">
      <div className="back-beam"></div>
      <div className="front-beam"></div>
      <div className="btn-beam"></div>
      <FiPlus color="#72ABFF" className="plus-icon" />
    </div>
  );
};

export default BeamingButton;
