






import './CharactersFamily.css';
import { Trans } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';

export default function CharactersFamily() {
    const [showHandIcon, setShowHandIcon] = useState(true);
    const splineRef = useRef();
    const [isMobile, setIsMobile] = useState(false);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);




    function onLoad(splineApp) {
        splineRef.current = splineApp;
    }

    const handleInteraction = () => {
        console.log('Spline interaction detected');
        window.postMessage('spline-interaction', '*');
        setShowHandIcon(false);
    };


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://unpkg.com/@splinetool/viewer@1.9.18/build/spline-viewer.js";
        script.type = "module";
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const updateWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateWidth);


        const handleMessage = (event) => {
            if (event.data === 'spline-interaction') {
                handleInteraction();
            }
        };
        const checkScreenSize = () => {
            setIsMobile(window.matchMedia('(max-width: 768px)').matches);
        };

        // Initial check
        checkScreenSize();

        // Add event listener to update on resize
        window.addEventListener('resize', checkScreenSize);
        window.addEventListener('message', handleMessage);


        return () => {
            window.removeEventListener('resize', updateWidth);
            window.removeEventListener('message', handleMessage);
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);


    const viewerStyle = {
        width: viewportWidth <= 480 ? "479px" : viewportWidth <= 1199 ? "1198px" : "1210px",
        height: viewportWidth <= 480 ? "300px" : "1210px",  // You can adjust the height as needed for different breakpoints
        // backgroundColor: "#acb7f5"
    };



    return (
        <>

            <div className="characters-container p-bottom-10-p m-top-10 p-5 ">
                <div className="row g-5 justify-content-center align-items-center">
                    <div className="col-12 col-md-4 order-md-2 justify-content-center align-items-center text-center text-align-center  mb-3">
                        <div className={`card border-0 ${!isMobile ? 'character-card' : ''}`}>
                            <div className="card-body text-center justify-content-center align-items-center ">
                                <p className='introduce-text  font-color-blue  '>
                                    <Trans i18nKey="charactersPage.family-title" />
                                </p>
                            </div>
                        </div>
                    </div>



                    <div className="col-6 col-md-4 order-md-1 mb-3">
                        <div className="card border-0  character-card ">
                            {showHandIcon && (
                                <img
                                    src="/images/characters/swipe.png"
                                    className="hand-icon"
                                    alt="Click to interact"
                                />
                            )}

                            <spline-viewer
                                url="https://prod.spline.design/tdJ8qVZu866K1SMf/scene.splinecode"
                                width="100%"
                                height="100%"
                                onLoad={onLoad}
                                onMouseDown={handleInteraction}
                                onTouchStart={handleInteraction}
                                className='spline-color'
                                style={{ ...viewerStyle, backgroundColor: "#acb7f5" }}
                            />

                        </div>
                        <div className="card-body character-card-body">
                            <p className="character-text-style character-mb">
                                <Trans i18nKey="charactersPage.family-1" />
                            </p>
                        </div>
                    </div>


                    <div className="col-6 col-md-4 order-md-3  mb-3">
                        <div className="card border-0 character-card">
                            {showHandIcon && (
                                <img
                                    src="/images/characters/swipe.png"
                                    className="hand-icon"
                                    alt="Click to interact"
                                />
                            )}
                            <spline-viewer
                                url="https://prod.spline.design/9N6-f6FM9dVanyDt/scene.splinecode"
                                width="100%"
                                height="100%"
                                onLoad={onLoad}
                                onMouseDown={handleInteraction}
                                onTouchStart={handleInteraction}
                                className='spline-color'
                                style={{ ...viewerStyle, backgroundColor: "#feeca8" }}

                            />

                        </div>
                        <div className="card-body character-card-body">
                            <p className="character-text-style character-mb">
                                <Trans i18nKey="charactersPage.family-5" />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row row-cols-2 row-cols-md-3 g-5 justify-content-center align-items-end">
                    <div className="col  mb-3">
                        <div className="card border-0  character-card">
                            {showHandIcon && (
                                <img
                                    src="/images/characters/swipe.png"
                                    className="hand-icon"
                                    alt="Click to interact"
                                />
                            )}
                            <spline-viewer
                                url="https://prod.spline.design/ZDOrW0P5jfexmmod/scene.splinecode"
                                width="100%"
                                height="100%"
                                onLoad={onLoad}
                                onMouseDown={handleInteraction}
                                onTouchStart={handleInteraction}
                                className='spline-color'
                                style={{ ...viewerStyle, backgroundColor: "#ffa4aa" }}

                            />

                        </div>
                        <div className="card-body character-card-body">
                            <p className="character-text-style mb-0">
                                <Trans i18nKey="charactersPage.family-3" />
                            </p>
                        </div>
                    </div>

                    <div className="col order-3 order-md-2  mb-3" style={{ marginTop: "0px" }}>
                        <div className="card border-0  character-card">
                            {showHandIcon && (
                                <img
                                    src="/images/characters/swipe.png"
                                    className="hand-icon"
                                    alt="Click to interact"
                                />
                            )}
                            <spline-viewer
                                  url="https://prod.spline.design/iXede6AuTIa8tjaR/scene.splinecode"
                                width="100%"
                                height="100%"
                                onLoad={onLoad}
                                onMouseDown={handleInteraction}
                                onTouchStart={handleInteraction}
                                className='spline-color'
                                style={{ ...viewerStyle, backgroundColor: "#ffddc6" }}

                            />
                        </div>
                        <div className="card-body character-card-body">
                            <p className="character-text-style mb-0">
                                <Trans i18nKey="charactersPage.family-4" />
                            </p>
                        </div>
                    </div>

                    <div className="col order-2 order-md-3  mb-3">
                        <div className="card border-0 character-card">
                            {showHandIcon && (
                                <img
                                    src="/images/characters/swipe.png"
                                    className="hand-icon"
                                    alt="Click to interact"
                                />
                            )}
                            <spline-viewer
                                 url="https://prod.spline.design/b7qp7T93Q6k3E28K/scene.splinecode"
                                width="100%"
                                height="100%"
                                onLoad={onLoad}
                                onMouseDown={handleInteraction}
                                onTouchStart={handleInteraction}
                                className='spline-color'
                                style={{ ...viewerStyle, backgroundColor: "#d7f8ff" }}

                            />
                        </div>
                        <div className="card-body character-card-body">
                            <p className="character-text-style mb-0">
                                <Trans i18nKey="charactersPage.family-2" />

                            </p>
                        </div>
                    </div>

                </div>

            </div >
        </>
    );
}





{/* <div className="row row-cols-2 row-cols-md-3 g-5 justify-content-center align-items-end">
<div className="col  mb-3">
    <div className="card border-0  character-card">
        {showHandIcon && (
            <img
                src="/images/characters/swipe.png"
                className="hand-icon"
                alt="Click to interact"
            />
        )}
        <iframe
            title="External Iframe"
            src="https://my.spline.design/wagzakcharacterpagedidi-317c66a4a324ba6ae6dfdbb5b3ca6f34/"
            width="100%"
            height="100%"
            className='spline-color'
            style={{ backgroundColor: "#ffa4aa" }}
        />

    </div>
    <div className="card-body character-card-body">
        <p className="character-text-style mb-0">
            <Trans i18nKey="charactersPage.family-3" />
        </p>
    </div>
</div>

<div className="col order-3 order-md-2  mb-3" style={{ marginTop: "0px" }}>
    <div className="card border-0  character-card">
        {showHandIcon && (
            <img
                src="/images/characters/swipe.png"
                className="hand-icon"
                alt="Click to interact"
            />
        )}
        <iframe
            title="External Iframe"
            src="https://my.spline.design/wagzakcharacterpageppuri-9bcb0aa495491a13628ab8cbdb9d578e/"
            width="100%"
            height="100%"
            className='spline-color'
            style={{ backgroundColor: "#ffddc6" }}
        />
    </div>
    <div className="card-body character-card-body">
        <p className="character-text-style mb-0">
            <Trans i18nKey="charactersPage.family-4" />
        </p>
    </div>
</div>

<div className="col order-2 order-md-3  mb-3">
    <div className="card border-0 character-card">
        {showHandIcon && (
            <img
                src="/images/characters/swipe.png"
                className="hand-icon"
                alt="Click to interact"
            />
        )}
        <iframe
            title="External Iframe"
            src="https://my.spline.design/wagzakcharacterpagebobo-0939f0881d8f65eb8ba2daf21bd97c92/"
            width="100%"
            height="100%"
            className='spline-color'
            style={{ backgroundColor: "#d7f8ff" }}
        />
    </div>
    <div className="card-body character-card-body">
        <p className="character-text-style mb-0">
            <Trans i18nKey="charactersPage.family-2" />

        </p>
    </div>
</div>

</div> */}




// --------------------------------------------------------

// import './CharactersFamily.css';
// import { Trans } from 'react-i18next';
// import React, { useState } from 'react';

// export default function CharactersFamily() {
//     // State to manage each hand icon visibility
//     const [showHandIcons, setShowHandIcons] = useState({
//         0: true,
//         1: true,
//         2: true,
//         3: true,
//         4: true
//     });

//     // Function to handle clicking on the hand icon
//     const handleIconClick = (index, event) => {
//         event.stopPropagation();  // Prevent the event from bubbling up to parent elements
//         setShowHandIcons(prev => ({
//             ...prev,
//             [index]: false  // Set the visibility of the clicked icon to false
//         }));
//     };

//     return (
//         <div className="characters-container p-bottom-10-p   ">
//             <div className="row justify-content-center align-items-center">
//                 <div className="col-12 col-md-4 order-md-2 justify-content-center align-items-center text-center text-align-center  mb-3">
//                     <div className={`card border-0  `}>
//                         <div className="card-body text-center justify-content-center align-items-center ">
//                             <p className='introduce-text  font-color-blue  '>
//                                 <Trans i18nKey="charactersPage.family-title" />
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//                 {Object.entries({
//                     "https://my.spline.design/wagzakcharacterpage-ad6f31a92df4001cad259f48ed734168/": "#acb7f5",
//                     "https://my.spline.design/untitled-475fb6ec36671ad87c963d0d7819a484/": "#feeca8",
//                     "https://my.spline.design/wagzakcharacterpagedidi-317c66a4a324ba6ae6dfdbb5b3ca6f34/": "#ffa4aa",
//                     "https://my.spline.design/wagzakcharacterpageppuri-9bcb0aa495491a13628ab8cbdb9d578e/": "#ffddc6",
//                     "https://my.spline.design/wagzakcharacterpagebobo-0939f0881d8f65eb8ba2daf21bd97c92/": "#d7f8ff"
//                 }).map(([url, bgColor], index) => (



//                     <div key={index} className={`col-6 col-md-4 mb-3 ${index === 0 ? 'order-md-1' : index === 2 ? 'order-md-3' : 'order-md-2'}`}>
//                         {/* <div className="card border-0 character-card" onClick={() => handleIconClick(index)}> */}
//                         <div className="card border-0 character-card" >

//                             {/* {showHandIcons[index] && (
//                                 <img
//                                     src="/images/characters/swipe.png"
//                                     className="hand-icon"
//                                     alt="Click to interact" 
//                                     style={{ cursor: 'pointer' }}
//                                 />
//                             )} */}
//                             <iframe
//                                 title="External Iframe"
//                                 src={url}
//                                 width="100%"
//                                 height="100%"
//                                 className='spline-color'
//                                 style={{ backgroundColor: bgColor }}
//                                 onClick={() => handleIconClick(index)}
//                             />
//                         </div>
//                         <div className="card-body character-card-body character-card">
//                             <p className="character-text-style character-mb">
//                                 <Trans i18nKey={`charactersPage.family-${index + 1}`} />
//                             </p>
//                         </div>
//                     </div>

//                 ))}
//             </div>
//         </div>
//     );
// }
