import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepIcon from '@mui/material/StepIcon';
import { styled } from '@mui/system';

const steps = [
  { label: 'Playjam', src: '/images/etc/playjam_rec.svg' },
  { label: 'Doremi', src: '/images/etc/doremi-rec.svg' },
  { label: 'Coloring', src: '/images/etc/coloring-rec.svg' },
];


// Custom StepIcon component
const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => {
  const primaryMain = theme?.palette?.primary?.main || '#1976d2'; // default to Material-UI primary.main color
  const grey300 = theme?.palette?.grey[300] || '#e0e0e0'; // default to Material-UI grey[300]

  return {
    backgroundColor: ownerState.completed ? primaryMain : grey300,
    zIndex: 1,
    color: 'white',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: "#86BEFF",
      boxShadow: '0 4px 10px 0 rgba(0,0,0,0.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: primaryMain,
    }),
  };
}); 

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {String(props.icon)}
    </CustomStepIconRoot>
  );
}


function StepperComponent() {
  return (
    <Box sx={{ width: '100%', marginTop: '10%' }}>
      <Stepper   alternativeLabel>

        {steps.map((step, index) => (

          <Step key={step.label}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <img src={step.src} alt={step.label} style={{ marginBottom: '8px' }} />
                {/* <Typography
                 sx={{ 
                  fontSize: '1rem', 
                  fontWeight: 'bold', 
                  color: '#3f51b5',
                  textAlign: 'center',
                }}
                >
                  {step.label}</Typography> */}
              </Box>
            </StepLabel>
          </Step>

        ))}

      </Stepper>
    </Box>
  );
}

export default StepperComponent;
