import ButtonPanel from '../../home/containers/ButtonPanel';
import React, { useState, useEffect } from 'react';

function WagzakAppHero() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  

  useEffect(() => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headerSvg = windowWidth < 767
    ? "/images/wagzakApp/wagzakApp_Header.svg"
    : "/images/wagzakApp/wagzakApp_img_headersvg.svg";

  return ( 
    <div>
      <img src={headerSvg} alt="Header SVG" style={{ backgroundColor: 'white', transition: 'opacity 0.4s ease-in-out'  }} /> 
      {/* <object data={headerSvg} type="image/svg+xml" style={windowWidth < 767 ? { backgroundColor: 'white' } : {}} /> */}
    </div>
  );
}

export default WagzakAppHero;
