import React from 'react';
import MailchimpForm from '../mailchimpServices/MailchimpForm';
import './FooterSec.css';
import { useTranslation, Trans } from 'react-i18next';


function EmailSubscribe() {
    const { t } = useTranslation();
    return (
        <div className='flex-container-column m-top-5 m-bottom-5 text-center'>
            <h1 className='email-title' ><Trans i18nKey="Mailchimp.text3" components={{ 1: <br /> }} /></h1>
            <p className='content-p-style margin-bottom-5'> <Trans i18nKey="Mailchimp.text-p" components={{ 1: <br /> }} /> </p>
            <MailchimpForm />
        </div>
    );

}
export default EmailSubscribe;