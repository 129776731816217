
import { createContext } from "react";

const defaultValue = {
    locale: 'en',
    setLocale: () => {}
}

const LocaleContext = createContext(defaultValue);

export default LocaleContext;


// import { createContext } from "react";

// const defaultValue = {
//     locale: 'en',
//     setLocale: () => {

//     }
// }

// export default createContext(defaultValue)