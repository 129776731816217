import React from 'react';
import './FooterContacts.css';
import { Link } from 'react-router-dom';


function FooterContacts() {
    const copyrightText =
        "Copyright © 2023" +
        (new Date().getFullYear() > 2023 ? "-" + new Date().getFullYear() : "") +
        " WAGZAK. All Rights Reserved.";
    return (
        <div className="footercontacts-style">
            <div className='container'>


                <div className='row align-items-center text-center privacy-text-layout g-1 p-5'>
                    <div className='col-md-2 col-12 logo-footer '> 
                        <Link to="/"> <img src="/images/logo/wagzaklogo.svg" alt="wagzak logo" className='logo_btn_mb img-fluid snslayout' />

                        </Link>
                    </div>


                    <div className='col-md-8 col-12 '>
                        <p>{copyrightText}</p>
                    </div>
                    <div className='col-md-2 col-12 logo-footer '>
                        <Link to="/policy/privacy" style={{ textDecoration: 'none' }}>
                            <p  >Privacy Policy</p>
                        </Link> 
                    </div>



                </div>

                <div className='row align-items-center text-center privacy-text-layout justify-content-center '>
                    <div className='col-md-2 col-2'>
                    </div>
                    <div className='col-md-8 col-12 mt-4'>
                        <p className='footer-contacts-p'>Find us</p>
                        <div className='row mb-layout mt-4 mb-5'>
                            <div className='col-md-2 col-2'>
                            </div>

                            <div className='col-md-2 col-2 snslayout'>
                                <a href="https://www.instagram.com/wagzak_official/?igshid=YTQwZjQ0NmI0OA%3D%3D">
                                    <img src="/images/footer/pub_btn_Instagram.svg" className='eng_btn img-fluid' style={{ width: "35px" }} />
                                </a>
                            </div>
                            <div className='col-md-2 col-2 snslayout'>
                                <a href="https://www.youtube.com/@WAGZAK_">
                                    <img src="/images/footer/pub_btn_youtube.svg" className='eng_btn img-fluid' />
                                </a>

                            </div>
                            <div className='col-md-2 col-2 snslayout  g-music'>
                                <a href="https://www.genie.co.kr/detail/albumInfo?axnm=84866089">
                                    <img src="/images/footer/pub_ico_sns_gienemusic.svg" className='eng_btn img-fluid' />
                                </a>
                            </div>

                            <div className='col-md-2 col-2 snslayout'>
                                <a href="https://music.apple.com/kr/album/wagzak-doremi/1734725747">
                                    <img src="/images/footer/pub_ico_sns_applemusic.svg" className='eng_btn img-fluid' />
                                </a>
                            </div>

                            <div className='col-md-2 col-2'>
                            </div>

                        </div>
                    </div>

                    <div className='col-md-2 col-2'>
                    </div>



                </div>



            </div>

        </div >


    );
}

export default FooterContacts;
