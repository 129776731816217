import { WagzakAppHero, WagzakAppOverview, WagzakAppFeatures, WagzakAppCharacters } from "./containers";
import './WagzakAppPage.css'
import FooterSec from "../../components/footer/FooterSec";
import FooterIconsTwo from "../../components/footer/FooterIconsTwo";
import FooterContacts from "../../components/footer/FooterContacts";
import EmailSubscribe from "../../components/footer/EmailSubscribe";
import { Header } from '../../components';
import { motion, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import FooterCharacters from "../../components/footer/FooterCharacters";



function WagzakAppPage({ analytics }) {
  const section1Ref = useRef(null);
  const [scrollTriggerHeight, setScrollTriggerHeight] = useState(0);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (section1Ref.current) { 
      setScrollTriggerHeight(section1Ref.current.offsetHeight * 0.8); 
    }
  }, []);

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <section className='wagzakApp-section1 bg-color-blue' ref={section1Ref}>
            <Header scrollTriggerHeight={scrollTriggerHeight}/>
            <WagzakAppHero />
          </section>
          <section className='wagzakApp-section2 bg-color-white'>
            <WagzakAppOverview />
          </section>

          <section>
            <WagzakAppFeatures />
          </section>

          <section>
            <WagzakAppCharacters />
          </section>

          <section>
            <EmailSubscribe />
          </section>

          <footer>
            <section>
              <FooterIconsTwo />
            </section>

            <section className="bg-color-green">
              <FooterSec />
            </section>

            <section className='bg-color-green'>
              <FooterContacts />
            </section>
            <section >
              <FooterCharacters />
            </section>


          </footer>
        </motion.div>
      </AnimatePresence>

    </>
  );
}

export default WagzakAppPage;

