
import './HomePlayground.css';
import React, { useState } from "react";
import HomeLottieAnimationSecond from "../../../components/lottieAnimationFiles/HomeLottieAnimationSecond";
import { useTranslation, Trans } from 'react-i18next';



function HomePlayground() {
    const { t } = useTranslation();
    const [showPopover, setShowPopover] = useState(false);

    const handlePlusBtnClick = () => {
        setShowPopover(!showPopover);
        // console.log(showPopover)
    };

    return (
        <div className="wagzak-playground-style">
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-12 col-sm-12 text-center'>
                        <h1 className="section4-title-style"> <Trans i18nKey="homePage.wagzakplayground-title" components={{ 1: <br /> }} /></h1>
                    </div>
                </div>

                <div className='row align-items-center section5-row-layout'>
                    <div className='col-md-4 col-12 text-center align-left-sm'>
                        <div className="card  d-flex flex-row justify-content-center align-items-center text-center " style={{ background: 'transparent', border: 'none' }}>
                            <img src="/images/home/main_img_cloud.svg" alt="cloud-feedback-img"  className="img-fluid cloud-small" />
                            <div className="card-img-overlay d-flex flex-column align-items-bottom justify-content-center">
                                <p className="card-text cloud-text-2 "> <Trans i18nKey="homePage.wagzakplayground-cloud2" components={{ 1: <br /> }} /></p>
                            </div>
                        </div>
                    </div>



                    <div className='col-md-4 col-12 text-center align-center-sm'>
                        <div className="card  d-flex flex-row justify-content-center align-items-center text-center " style={{ background: 'transparent', border: 'none' }}>


                            <img src="/images/home/main_img_cloud.svg" alt="cloud-feedback-img" className="img-fluid cloud-large" />
                            <div className="card-img-overlay d-flex flex-column align-items-bottom justify-content-center">
                                <p className="card-text cloud-text-2 "> <Trans i18nKey="homePage.wagzakplayground-cloud3" components={{ 1: <br /> }} /></p>
 
                            </div>
                        </div>
                    </div>


                    <div className='col-md-4 col-12 text-center align-right-sm'>
                        <div className="card  d-flex flex-row justify-content-center align-items-center text-center " style={{ background: 'transparent', border: 'none' }}>

                            <img src="/images/home/main_img_cloud.svg" alt="cloud-feedback-img" className="img-fluid cloud-medium" />
                            <div className="card-img-overlay d-flex flex-column align-items-bottom justify-content-center">
                                <p className="card-text cloud-text-2"> <Trans i18nKey="homePage.wagzakplayground-cloud1" components={{ 1: <br /> }} /></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ marginBottom: '-5px' }}>
                <HomeLottieAnimationSecond />
            </div>



        </div >
    );
}

export default HomePlayground;
