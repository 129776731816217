import { fontStyle } from '@mui/system';
import './WagzakAppCharacters.css';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';


function WagzakAppCharacters() {

    const { t } = useTranslation();
    return (
        <div className="container flex-container-column m-top-10 m-bottom-10">
            <h1 className="content-main-title-ef font-color-orange m-bottom-10 "><Trans i18nKey="wagzakAppPage.section4-title" components={{ 1: <br /> }} /></h1>

            <div className="row row-cols-2 row-cols-md-3 g-5 justify-content-center align-items-center">
                <div className="col">
                    <div className="card border-0">
                        <img src="/images/wagzakApp/wagzakApp_bangu.svg" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <p className="character-p-style"><Trans i18nKey="wagzakAppPage.section4-text1" components={{ span: <span style={{ color: '#9322c3', fontWeight: 'bold' }} /> }} /></p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card border-0" >
                        <img src="/images/wagzakApp/wagzakApp_ppuri.svg" style={{ maxHeight: '300px' }} className="card-img-top" alt="..." />
                        <div className="card-body" >
                            <p className="character-p-style"><Trans i18nKey="wagzakAppPage.section4-text2" components={{ span: <span style={{ color: '#43ac2d', fontWeight: 'bold' }} /> }} /></p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card border-0">
                        <img src="/images/wagzakApp/wagzakApp_halmi.svg" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <p className="character-p-style"><Trans i18nKey="wagzakAppPage.section4-text3" components={{ span: <span style={{ color: '#ffaa32', fontWeight: 'bold' }} /> }} /></p>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="card border-0">
                        <img src="/images/wagzakApp/wagzakApp_didi.svg" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <p className="character-p-style">
                                <Trans i18nKey="wagzakAppPage.section4-text4" components={{ span: <span style={{ color: '#ff6363', fontWeight: 'bold' }} /> }} /></p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card border-0">
                        <img src="/images/wagzakApp/wagzakApp_bobo.svg" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <p className="character-p-style"><Trans i18nKey="wagzakAppPage.section4-text5" components={{ span: <span style={{ color: '#42cbdb', fontWeight: 'bold' }} /> }} /></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default WagzakAppCharacters;
