import './FooterIconsTwo.css';

function FooterIconsTwo() {
    return (
        <div className="footerIconTwo-style"> 
            <object data="/images/footer/pub_family_footer.svg" style={{width:'100%', marginBottom:'-10px'}}  type="image/svg+xml"/>
        </div>

    );
}

export default FooterIconsTwo;