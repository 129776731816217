 
import './AboutValues.css';
import React, { useState } from 'react';
import i18next from '../../../i18n'
import { useTranslation, Trans } from 'react-i18next'; 
import { motion } from 'framer-motion'; 


function AboutValues() {
    const { t } = useTranslation();

    return (
        <> 
            <div className="aboutus-section2-style">
 
                <div className='row  ' style={{ marginBottom: '5%' }}>


                    <div className='col-2 col-md-2'>  <img src="/images/aboutUs/about us_img_Ppuri.svg" style={{ width: '100%' }} alt="" /> </div>
                    <div className='col-8 col-md-8 d-flex justify-content-center'>
                        <div className="text-center align-self-center">
                            <h5 className='aboutus-section2-title'>ABOUT US</h5>

                            <p className='aboutus-section2-text'>
                                <Trans i18nKey="aboutUsPage.section2-text1" components={{ 1: <br /> }} />
                            </p>

 
                        </div>
                    </div>

                    <div className='col-2 col-md-2 '>
                        <img src="/images/aboutUs/about us_img_okto.svg" alt="" style={{ width: '100%' }} />
                    </div>
                </div>



                <div className='row align-items-center text-center justify-content-center ' >

                    <div className='col-12 col-md-3' >
                    </div>
                    <div className='col-12 col-md-6' style={{ marginBottom: '5%' }}>
                        <h5 className="aboutus-section2-title">CORE VALUE</h5>
                        <p className=" aboutus-section2-text">
                            <Trans i18nKey="aboutUsPage.section2-text2" components={{ 1: <br /> }} />
                        </p>
                       

                    </div>

                  
                    <motion.div
                        className="col-12 col-md-3 halmi-mg"
                        whileHover={{ scale: 1.1 }}  
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                        <img src="/images/aboutUs/about us_img_halme with banggu.svg" style={{ width: '70%' }} alt="" />
                    </motion.div>

                </div>
            </div>
        </>
    );
}

export default AboutValues;