import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './PlayjamOverview.css';
import { Link } from 'react-router-dom';
import axios from 'axios'; 


const PlayjamOverview = () => {
  const [contentData, setContentData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [error, setError] = useState('');
  const { i18n } = useTranslation();
  const language = i18n.language.split('-')[0] || 'en-US';
  console.log("Simplified language code:", language);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchContent = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/contents/all`;
      try {
        const response = await axios.get(apiUrl);
        const sortedData = response.data.sort((a, b) => b.contentID - a.contentID);

        // With Axios, the response data is located in the `.data` property
        setContentData(sortedData);
        console.log("Sorted data", sortedData);

        response.data.forEach(item => {
          console.log('enlsih tranlsation->', item.englishTranslation)
        })
      } catch (error) {
        // Axios wraps the error response in the `error.response` object
        // `error.message` is available directly for logging purposes
        console.error('Failed to fetch content', error.message);
        setError(
          `Failed to fetch content: ${error.response ? error.response.data : error.message
          }`
        );
      }
    };

    fetchContent();
  }, [language]);

  const handleShowAllClick = () => {
    setShowAll(!showAll);
  };


  return (
    <>
      <div className="container flex-container-column m-top-3 m-bottom-5">
        <div className="container align-items-center text-center">
          <h1
            className="content-main-title-ef font-color-orange">
            <Trans
              i18nKey="playjamPage.section2-title"
              components={{ 1: <br /> }}
            />
          </h1>

          <p className="content-p-style">
            <Trans
              i18nKey="playjamPage.section2-title-p"
              components={{ 1: <br /> }}
            />
          </p> 

          <div className="row row-cols-1 row-cols-md-2 g-5 p-5">
            {contentData
              .slice(0, showAll ? contentData.length : 4)
              .map((content, index) => (
                <div key={index} className="col">

                  <div
                    className="card h-100 border-0"
                    style={{ width: '100%', height: '450px' }}
                  >
                    {language === 'en' && (
                      <><div
                        className="image-container"
                        style={{ position: 'relative' }}
                      >
                        <Link to={`/playjam/all/${content.contentID}`} style={{ textDecoration: "none", color: "inherit" }}>


                          <img
                            src={content.englishTranslation.contentImage}
                            className="card-img-top rounded"
                            style={{
                              maxWidth: '100%',
                              width: '567px',
                              maxHeight: '100%',
                              height: 'auto',
                              objectFit: 'contain',
                            }}
                            alt={content.englishTranslation.contentTitle} />

                        </Link>

                      </div><div className="card-body">
                          <h5 className="card-title playjam-section2-title">
                            {content.englishTranslation.contentTitle}
                          </h5>
                          <p className="card-text playjam-section2-text">
                            {content.englishTranslation.contentDescription}
                          </p>
                        </div></>

                    )}

                    {language === 'kr' && (
                      <><div
                        className="image-container"
                        style={{ position: 'relative' }}
                      >
                        <Link to={`/playjam/all/${content.contentID}`} style={{ textDecoration: "none", color: "inherit" }}>
                          <img
                            src={content.koreanTranslation.contentImage}
                            className="card-img-top rounded"
                            style={{
                              maxWidth: '100%',
                              width: '567px',
                              maxHeight: '100%',
                              height: 'auto',
                              objectFit: 'contain',
                            }}
                            alt={content.koreanTranslation.contentTitle} />
                        </Link>


                      </div><div className="card-body">
                          <h5 className="card-title playjam-section2-title">
                            {content.koreanTranslation.contentTitle}
                          </h5>
                          <p className="card-text playjam-section2-text">
                            {content.koreanTranslation.contentDescription}
                          </p>
                        </div></>

                    )}

                  </div>

                </div>
              ))}
          </div>

          {/* <button
              className="playjam-btn-style2" 
              onClick={handleShowAllClick}
            >
              {showAll ? <Trans i18nKey="playjamPage.section2-pdf-btn-less" /> : <Trans i18nKey="playjamPage.section2-pdf-btn-all" />}
            </button> */}
          {error && <p>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default PlayjamOverview;




