 

import React, { useEffect, useRef } from 'react';
import './AnimatedFooter.css';
import { gsap, ScrollTrigger, Draggable } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, Draggable);

const characters = [
    { id: 1, name: 'panda', image: '/images/character_img_Panpa.svg', x: 50, y: 100, animation: { scale: [0.5, 1], opacity: [0, 1], duration: 1, ease: "bounce.out" } },
    { id: 2, name: 'cat', image: '/images/character_img_Blue.svg', x: 200, y: 150, animation: { scale: [0.7, 1], opacity: [0, 1], duration: 1.5, ease: "elastic.out" } },
];

const items = [
    { id: 1, name: 'sun', image: '/images/sun.svg', x: 60, y: 50, animation: { scale: [1, 2], opacity: [0, 1], duration: 2, ease: "power2.inOut" } },
    { id: 2, name: 'cloud', image: '/images/cloud.svg', x: 150, y: 200, animation: { scale: [1, 1.5], opacity: [0, 1], duration: 3, ease: "slow(0.7, 0.7, false)", scrollTrigger: true } },
];

function AnimatedFooter() {
    const footerRef = useRef();
    const characterRefs = useRef([]);
    const itemRefs = useRef([]);
    const scrollContainerRef = useRef();

    useEffect(() => {

        // Apply animations and draggable functionality
        characters.forEach((char, index) => {
            const el = characterRefs.current[index];
            if (el) {
                gsap.set(el, { x: char.x, y: char.y });
                applyAnimations(el, char);
                Draggable.create(el, { bounds: footerRef.current });
            }
        });

        items.forEach((item, index) => {
            const el = itemRefs.current[index];
            if (el) {
                gsap.set(el, { x: item.x, y: item.y });
                applyAnimations(el, item);
                Draggable.create(el, { bounds: footerRef.current });
            }
        });


    }, []);

    const applyAnimations = (ref, settings) => {
        const baseAnimation = {
            scale: settings.animation.scale[1],
            opacity: settings.animation.opacity[1],
            duration: settings.animation.duration,
            ease: settings.animation.ease,
        };

        if (settings.animation.scrollTrigger) {
            baseAnimation.scrollTrigger = {
                trigger: ref,
                scrub: true,
                start: "top center",
                end: "bottom center",
                // markers: true
            };
        }

        gsap.fromTo(ref, {
            scale: settings.animation.scale[0],
            opacity: settings.animation.opacity[0],
        }, baseAnimation);
    };

    const handleClick = (item) => {
        console.log(`${item.name} clicked!`);
        // Additional logic can be added here for interaction
    };

    return (
        <div className="footer-container" ref={footerRef}>
            <div className="scroll footer-st" ref={scrollContainerRef}>
                {characters.map((char, index) => (
                    <img key={char.id} src={char.image} alt={char.name}
                        ref={el => characterRefs.current[index] = el}
                        className="draggable-character"
                        onClick={() => handleClick(char)}
                    />
                ))}
                {items.map((item, index) => (
                    <img key={item.id} src={item.image} alt={item.name}
                        ref={el => itemRefs.current[index] = el}
                        className="draggable-character"
                        onClick={() => handleClick(item)}
                    />
                ))}
            </div>
        </div>
        
    );
}

export default AnimatedFooter;
