import './FooterIcons.css';

function FooterIcons() {
    return (
        <div className="footerIcons-style">

            <object data="/images/footer/main_footer_wagzak_img.svg" style={{ width: '100%', marginBottom: '-10px' }} type="image/svg+xml" />

        </div>

    );
}

export default FooterIcons;