import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { jwtDecode } from 'jwt-decode'; // Correct import

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     if (currentUser) {
  //       navigate('/account/user-data');
  //     }
  //   }, [currentUser, navigate]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem('accessToken');
          setCurrentUser(null);
        } else {
          setCurrentUser(decodedToken);
        }
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('accessToken');
      }
    }
    setLoading(false);

    const checkTokenExpiry = () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp - currentTime < 300) { // Refresh if less than 5 minutes to expiry
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
              axios.post('http://localhost:5001/api/v1/users/refresh-token', { token: refreshToken })
                .then(response => {
                  const newAccessToken = response.data.accessToken;
                  localStorage.setItem('accessToken', newAccessToken);
                  setCurrentUser(jwtDecode(newAccessToken));
                })
                .catch(error => {
                  console.error('Refresh token error:', error);
                  logout();
                });
            } else {
              logout();
            }
          }
        } catch (error) {
          console.error('Invalid token:', error);
          logout();
        }
      }
    };

    const intervalId = setInterval(checkTokenExpiry, 60000); // Check every minute
    return () => clearInterval(intervalId);

  }, []);

  const login = async (values, setErrors) => {
    try {
      const response = await axios.post('http://localhost:5001/api/v1/users/signin', values);
      console.log('Server response:', response.data);
      const { accessToken, refreshToken } = response.data.data;
      console.log('accessToken:', accessToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      setCurrentUser(jwtDecode(accessToken));
      navigate('/account/user-data');
    } catch (error) {
      console.error("Sign error:", error);
      // Handle login error
      if (error.response) {
        if (error.response.status === 404) {
          setErrors({ username: error.response.data.message });
        } else if (error.response.status === 401) {
          setErrors({ password: error.response.data.message })
        } else {
          alert("An error occurred. Please try again.");
        }
      } else if (error.request) {
        console.error("Error request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setCurrentUser(null);
    navigate('/');
  };

  const value = {
    currentUser,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}




// import React, { useContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../helpers/AuthContext'; // Import the context
// // const { default: jwt_decode } = require("jwt-decode");
// import { jwtDecode } from 'jwt-decode';


// export function useAuth() {
//     return useContext(AuthContext);
// }

// export function AuthProvider({ children }) {
//     const [currentUser, setCurrentUser] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const token = localStorage.getItem('accessToken');
//         if (token) {
//             try {
//                 // Decode the token to check its expiration
//                 const decodedToken = jwtDecode(token);
//                 const currentTime = Date.now() / 1000;
//                 if (decodedToken.exp < currentTime) {
//                     // Token has expired
//                     localStorage.removeItem('accessToken');
//                     setCurrentUser(null);
//                 } else {
//                     // Token is valid
//                     setCurrentUser(decodedToken);
//                 }
//             } catch (error) {
//                 console.error('Invalid token:', error);
//                 localStorage.removeItem('accessToken');
//             }

//         }
//         setLoading(false);

//         const checkTokenExpiry = () => {
//             const token = localStorage.getItem('accessToken');
//             if (token) {
//               const decodedToken = jwtDecode(token);
//               const currentTime = Date.now() / 1000;
//               if (decodedToken.exp - currentTime < 300) { // Refresh if less than 5 minutes to expiry
//                 axios.post('http://localhost:5001/api/v1/users/refresh-token', { token: localStorage.getItem('refreshToken') })
//                   .then(response => {
//                     localStorage.setItem('accessToken', response.data.accessToken);
//                     setCurrentUser(jwtDecode(response.data.accessToken));
//                   })
//                   .catch(error => {
//                     console.error('Refresh token error:', error);
//                     logout();
//                   });
//               }
//             }
//           };

//           const intervalId = setInterval(checkTokenExpiry, 60000);
//           return () => clearInterval(intervalId);


//     }, []);

//     const login = async (values) => {
//         const response = await axios.post('http://localhost:5001/api/v1/users/signin', values);
//         // const token = response.data.data.token;
//         // localStorage.setItem("accessToken", token);
//         const {accessToken, refreshToken} = response.data.data;
//         localStorage.setItem("accessToken", accessToken);
//         localStorage.setItem("refreshToken", refreshToken);
//         setCurrentUser(jwtDecode(accessToken));
//         navigate('/account/user-data');
//     };

//     const logout = () => {
//         // localStorage.removeItem('accessToken');
//         localStorage.removeItem('accessToken');
//         localStorage.removeItem('refreshToken');
//         setCurrentUser(null);
//         navigate('/');
//     };

//     const value = {
//         currentUser,
//         login,
//         logout
//     };

//     return (
//         <AuthContext.Provider value={value}>
//             {!loading && children}
//         </AuthContext.Provider>
//     );
// }


// import React, { useContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../helpers/AuthContext'; // Import the context

// export function useAuth() {
//   return useContext(AuthContext);
// }

// export function AuthProvider({ children }) {
//   const [currentUser, setCurrentUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('accessToken');
//     if (token) {
//       // Validate the token
//       axios.post('http://localhost:5001/api/v1/users/validate-token', {}, {
//         headers: { jwt_access_token: token }
//       }).then(response => {
//         setCurrentUser(response.data.data);
//       }).catch(() => {
//         localStorage.removeItem('accessToken');
//         setCurrentUser(null);
//       }).finally(() => {
//         setLoading(false);
//       });
//     } else {
//       setLoading(false);
//     }
//   }, []);

//   const login = async (values) => {
//     const response = await axios.post('http://localhost:5001/api/v1/users/signin', values);
//     localStorage.setItem("accessToken", response.data.data.token);
//     setCurrentUser(response.data.data);
//     navigate('/account/user-data');
//   };

//   const logout = () => {
//     localStorage.removeItem('accessToken');
//     setCurrentUser(null);
//     navigate('/');
//   };

//   const value = {
//     currentUser,
//     login,
//     logout
//   };

//   return (
//     <AuthContext.Provider value={value}>
//       {!loading && children}
//     </AuthContext.Provider>
//   );
// }
