import './FooterIconsAboutUs.css';
import { useTranslation, Trans } from 'react-i18next';


function FooterIconsAboutUs() {

    const { t } = useTranslation();

    return (
        <div className="footerIconsAboutus-style"> 
                <div className="row row-cols-1 row-cols-md-2 text-center justify-content-center  align-items-center">
                    <div className="col align-items-center justify-content-center footerabout-us"  >

                        <p className="footerAboutUs-title-style">
                            <Trans i18nKey="aboutUsPage.footer-text1" components={{ 1: <br /> }} />
                        </p>
                        <p className="footerAboutUs-text-style">
                            <Trans i18nKey="aboutUsPage.footer-text2" components={{ 1: <br /> }} />
                        </p>



                    </div>
                    <div className="col" style={{ padding: '5%' }}>


                        <img src="/images/aboutUs/about us_img_house.svg" className='img-fluid max-width-house' alt="..." style={{ paddingBottom: "20%" }} />

                    </div>
                </div>

        
        </div>

    );
}

export default FooterIconsAboutUs;