

import React, { useEffect, useRef } from 'react';
import './FooterPlayground.css';
import { gsap, ScrollTrigger, Draggable } from 'gsap/all';
import Lenis from '@studio-freight/lenis';
gsap.registerPlugin(ScrollTrigger, Draggable);

function FooterPlayground() {
    const scrollRef = useRef(null);

    useEffect(() => {

        const scroller = scrollRef.current;
        const sections = gsap.utils.toArray(".scroll section");

        const scrollTween = gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none",
            scrollTrigger: {
                trigger: scroller,
                pin: true,
                scrub: 1,
                snap: 1 / (sections.length - 1),
                end: () => "+=" + scroller.offsetWidth
            }
        });

        sections.forEach(section => {
            gsap.from(section.querySelector('h1'), {
                opacity: 0,
                y: -100,
                scrollTrigger: {
                    containerAnimation: scrollTween,
                    trigger: section.querySelector('h1'),
                    start: 'left center',
                    markers: true,
                    toggleActions: 'play none none reverse'
                }
            });
        });


        const lenis = new Lenis();

        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);
    }, []);

    return (
        <div className="footer-container" >
            {/* <section style={{ backgroundColor: 'purple' }} className='footer-sections'>
                <h3>hi</h3>
            </section> */}
            <div className="outer" ref={scrollRef}>
                <div className="scroll">
                    <section className='footer-sections'>
                        {/* <div className="bgr-img"></div> */}
                        <h1>hey</h1>
                    </section>
                    <section className='footer-sections'>
                        <h1>Great! Thanks for asking.</h1>

                        {/* <div className="bgr-img"></div> */}
                    </section>
                    <section className='footer-sections'>
                        <h1>Its wagzakkkkkkkk</h1>
                    </section>
                </div>
            </div>
            {/* <section className='footer-sections'>
                <h3>bye</h3>
            </section> */}
        </div>

    );
}

export default FooterPlayground;

