import React, { useEffect, useState } from "react";
import "./ContentRequest.css";
import { useTranslation, Trans } from "react-i18next";

import { logEvent } from "firebase/analytics";

import { useFormik } from "formik";
import * as Yup from "yup";

function ContentRequest({ analytics }) {
  const { t, i18n } = useTranslation();

  const placeholders =
    t("homePage.content-req-dropdownOptions.placeholder", {
      returnObjects: true,
    }) || {}; 
  const dropdownOptionsUser =
    t("homePage.content-req-dropdownOptions.user.options", {
      returnObjects: true,
    }) || [];
  const dropdownOptionsContent =
    t("homePage.content-req-dropdownOptions.content.options", {
      returnObjects: true,
    }) || [];

  const [showCustomOccupationInput, setShowCustomOccupationInput] =
    useState(false);
  const [showCustomContentTypeInput, setShowCustomContentTypeInput] =
    useState(false);

  const [result, setResult] = useState("");
  const [resultColor, setResultColor] = useState("");

  const onSubmit = async (values, actions) => {
    console.log("submitted and values->", values);
 
    const occupation =
      values.occupation === dropdownOptionsUser.other
        ? values.customOccupation
        : values.occupation;
    const contentType =
      values.contentType === dropdownOptionsContent.other
        ? values.customContentType
        : values.contentType;

    const language = i18n.language;

    const payload = {
      ...values,
      occupation,
      contentType,
      language,
    };

    console.log("valuesss", values);

    const headers = new Headers();
    headers.append("Accept-Language", values.language);
    try {
      // Send a POST request to your API with the form data in the body
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/content-request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (result.status === "success") {
        setResult("Your request has been submitted successfully.");
        setResultColor("#47cfa7");
        // setTimeout(() => setResult(""), 2000);
        //or make some alert show up 
      } else {
        setResult("An error occurred. Please try again.");
        setResultColor("#ff4945"); 
      }
    } catch (error) {
      setResult(
        "An error occurred. Please check your network connection and try again."
      );
      setResultColor("#ff4945"); 
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm(); 
  };

  const basicSchema = Yup.object().shape({
    occupation: Yup.string().required("Occupation is required"),
    customOccupation: Yup.string().when("occupation", {
      is: dropdownOptionsUser.other,
      then: () => Yup.string().required("Custom Occupation is required"),
    }),
    contentType: Yup.string().required("Content Type is required"),
    customContentType: Yup.string().when("contentType", {
      is: dropdownOptionsUser.other,
      then: () => Yup.string().required("Custom Content Type is required"),
    }),
    contentText: Yup.string().required(
      "Content Text is required and should not be empty."
    ),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required and should not be empty."),
    contentLocation: Yup.string().required(
      "Content Location is required and should not be empty."
    ),
    school: Yup.string().when("occupation", {
      is: dropdownOptionsUser.teacher,
      then: () => Yup.string().required("School is required."),
    }),
    grade: Yup.string().when("occupation", {
      is: dropdownOptionsUser.teacher,
      then: () => Yup.string().required("Grade is required."),
    }),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      occupation: "",
      customOccupation: "",
      customContentType: "",
      contentType: "",
      school: "",
      grade: "",
      contentText: "",
      email: "",
      contentLocation: "",
      language: i18n.language,
    },
    validationSchema: basicSchema,
    onSubmit,
  });

  useEffect(() => {
    i18n.changeLanguage(values.language);
  }, [values.language, i18n]);

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <h1
          className="content-req-title text-center "
          style={{ color: "#FBA056" }}
        >
          <Trans
            i18nKey="homePage.content-req-title"
            components={{ 1: <br /> }}
          />
        </h1>
        <p className="content-req-p text-center mt-5">
          <Trans
            i18nKey="homePage.content-req-text"
            components={{ 1: <br /> }}
          />
        </p>
        <p className="content-req-title text-center mt-5 font-color-blue">
          <Trans
            i18nKey="homePage.content-req-text2"
            components={{ 1: <br /> }}
          />
        </p>
        <div className="container" style={{ padding: "3% 10%" }}>
          <div className="row">
            <div className="col col-md-3 col-6">
              <div className="card" style={{ border: "none" }}>
                <img
                  src={t(`homePage.stepsImages.step1`)}
                  className="card-img-top"
                />
                <div className="card-body">
                  <p className="card-text typography-style">
                    <Trans>{`homePage.steps.step1`}</Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-md-3 col-6">
              <div className="card" style={{ border: "none" }}>
                <img
                  src={t(`homePage.stepsImages.step2`)}
                  className="card-img-top  "
                />
                <div className="card-body">
                  <p className="card-text typography-style">
                    <Trans>{`homePage.steps.step2`}</Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-md-3 col-6">
              <div className="card" style={{ border: "none" }}>
                <img
                  src={t(`homePage.stepsImages.step3`)}
                  className="card-img-top "
                />
                <div className="card-body">
                  <p className="card-text typography-style">
                    <Trans>{`homePage.steps.step3`}</Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-md-3 col-6">
              <div className="card" style={{ border: "none" }}>
                <img
                  src={t(`homePage.stepsImages.step4`)}
                  className="card-img-top  "
                />
                <div className="card-body">
                  <p className="card-text typography-style">
                    <Trans>{`homePage.steps.step4`}</Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className="row">
              <div className="col-md-6 col-12 col-sm-12 mb-4">
                <div className="d-flex flex-column align-items-md-end justify-content-center align-items-center">
                  <select
                    className="input-style input-style2 p-2"
                    name="occupation"
                    value={values.occupation}
                    onChange={(event) => {
                      handleChange(event);
                      setShowCustomOccupationInput(
                        event.target.value === dropdownOptionsUser.other
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled hidden>
                      {placeholders.occupation}
                    </option>

                    {Object.keys(dropdownOptionsUser).map((key) => (
                      <option key={key} value={dropdownOptionsUser[key]}>
                        {dropdownOptionsUser[key]}
                      </option>
                    ))}
                  </select>
                  {errors.occupation && touched.occupation && (
                    <p className="error-message2">{errors.occupation}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6 col-12 col-sm-12 mb-4">
                <div className="d-flex flex-column  align-items-md-start justify-content-center align-items-center ">
                  <select
                    className="input-style input-style2 p-2"
                    name="contentType"
                    value={values.contentType}
                    onChange={(event) => {
                      handleChange(event);
                      setShowCustomContentTypeInput(
                        event.target.value === dropdownOptionsContent.other
                      );
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled hidden>
                      {placeholders.contentType}
                    </option>
                    {Object.keys(dropdownOptionsContent).map((key) => (
                      <option key={key} value={dropdownOptionsContent[key]}>
                        {dropdownOptionsContent[key]}
                      </option>
                    ))}
                  </select>
                  {errors.contentType && touched.contentType && (
                    <p className="error-message2">{errors.contentType}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {values.occupation === dropdownOptionsUser.teacher && (
            <div className="col col-12 ">
              <div className="row">
                <div className="col-md-6 col-12 col-sm-12 mb-4  ">
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <div className="input-container align-self-md-end ">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text prepend-style">
                            {placeholders.school}
                          </span>
                        </div>
                        <input
                          type="text"
                          name="school"
                          className="form-control costum-input-style input-style"
                          value={values.school}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.school && touched.school && (
                        <p className="error-message2">{errors.school}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 col-sm-12 mb-4">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="input-container align-self-md-start ">
                      <div className="input-group ">
                        <div className="input-group-prepend ">
                          <span className="input-group-text prepend-style">
                            {placeholders.grade}
                          </span>
                        </div>
                        <input
                          type="text"
                          name="grade"
                          className="form-control costum-input-style input-style"
                          value={values.grade}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {errors.grade && touched.grade && (
                        <p className="error-message2">{errors.grade}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {values.occupation === dropdownOptionsUser.other &&
            showCustomOccupationInput && (
              <>
                <div className="col col-12 mb-4">
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <div className=" input-style   ">
                      <div className="input-group">
                        <div className="input-group-prepend ">
                          <span className="input-group-text prepend-style  ">
                            {placeholders.occupation}
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control costum-input-style2 "
                          placeholder={placeholders.customInput}
                          value={values.customOccupation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="customOccupation"
                          style={{ color: "#827c7c" }}
                        />
                      </div>
                      {errors.customOccupation && touched.customOccupation && (
                        <p className="error-message2">
                          {errors.customOccupation}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

          {values.contentType === dropdownOptionsContent.other &&
            showCustomContentTypeInput && (
              <>
                <div className="col col-12 mb-4">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="input-style">
                      <div className="input-group ">
                        <div className="input-group-prepend">
                          <span className="input-group-text prepend-style">
                            {placeholders.contentType}
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control costum-input-style2"
                          placeholder={placeholders.customInput}
                          value={values.customContentType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ color: "#827c7c" }}
                          name="customContentType"
                        />
                      </div>
                      {errors.customContentType &&
                        touched.customContentType && (
                          <p className="error-message2">
                            {errors.customContentType}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}

          <div className="col col-12">
            <div className="input-layout">
              <textarea
                className="input-style p-4 pt-5"
                style={{ height: "200px" }}
                name="contentText"
                placeholder={placeholders.textArea}
                value={values.contentText}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.contentText && touched.contentText && (
              <p className="error-message">{errors.contentText}</p>
            )}
          </div>

          <div className="col col-12 mb-4 mt-4">
            <div className="input-layout">
              <input
                type="email"
                name="email"
                className={`  input-style p-4 ${
                  errors.email && touched.email ? "input-error" : ""
                }`}
                placeholder={placeholders.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            {errors.email && touched.email && (
              <p className="error-message">{errors.email}</p>
            )}
          </div>

          <div className="col col-12 mb-5">
            <div className="input-layout">
              <input
                type="text"
                name="contentLocation"
                className={`input-style p-4 `}
                placeholder={placeholders.location}
                value={values.contentLocation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {errors.contentLocation && touched.contentLocation && (
              <p className="error-message">{errors.contentLocation}</p>
            )}
          </div>

          <div className="col col-12 mb-4">
            <div className="form-group input-layout">
              <button
                className="btn-style"
                disabled={isSubmitting}
                onClick={() => {
                  logEvent(analytics, `Content_Request_Submitted`);
                }}
                type="submit"
              >
                <Trans
                  i18nKey="homePage.content-req-dropdownOptions.btn"
                  components={{ 1: <br /> }}
                />
              </button>
            </div>
          </div>
          <div className="mb-5">
          {isSubmitting ? <p className="p-style">Sending...</p> : <h3 className="result-style" style={{ color: resultColor }}>{result}</h3>}
            </div>
        </div>
      </form>
    </div>
  );
}

export default ContentRequest;
 