import './HomePlayjam.css';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';


function HomePlayjam() {
  const { t } = useTranslation();

  // tracking width of screent
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // console.warn("text")

  useEffect(() => {
    // Update the window width when it changes
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      // console.log(windowWidth);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth < 767 ? (
        <>
          <div className="home-playjam-style ">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 d-flex justify-content-center align-items-center">
                <div
                  className="col-md-5 col-12 text-center order-md-1 order-2 mb-style"
                  style={{ padding: '5%' }}
                >
                  <div
                    className="card"
                    style={{
                      background: 'transparent',
                      border: 'none',
                      marginBottom: '30%',
                    }}
                  >
                    <img
                      src="/images/home/playjam_text_block.svg"
                      alt=""
                      className="card-img "
                      style={{ maxWidth: '100%', height: '100%' }}
                    />

                    <div className="card-img-overlay d-flex flex-column align-items-bottom justify-content-center">
                      <div className="">
                        <p className="card-text playjam-text-1 ">
                          Fun begins with
                        </p>
                        <h5 className="card-title playjam-text-2 ">
                          Playjam
                        </h5>
                        <p className="card-text playjam-text-3 display-8">
                          <Trans
                            i18nKey="homePage.playjam-text"
                            components={{ 1: <br /> }}
                          />{' '}
                        </p>

                        <Link to={`/playjam`} target="_blank" >
                          <button type="button" className="wagzak-playjam-btn">
                            <span >
                              <Trans
                                i18nKey="Button.Learnmore"
                                components={{ 1: <br /> }}
                              />
                            </span>
                          </button>
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7 col-12 text-center order-md-2 order-1"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="home-playjam-style ">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 d-flex justify-content-center align-items-center">
                <div
                  className="col-md-5 col-12 text-center order-md-1 order-2 mb-style"
                  style={{ padding: '5%' }}
                >
                  <div
                    className="card testing"
                    style={{
                      background: 'transparent',
                      border: 'none',
                      marginBottom: '30%',
                    }}
                  >
                    <img
                      src="/images/home/playjam_text_block.svg"
                      alt=""
                      className="card-img img-fluid"
                      style={{ maxWidth: '100%', height: '100%' }}
                    />
                    <div className="card-img-overlay d-flex flex-column align-items-bottom justify-content-center">
                      <div className="">
                        <p className="card-text playjam-text-1  ">
                          Fun begins with
                        </p>
                        <h5 className="card-title playjam-text-2  ">
                          Playjam
                        </h5>
                        <p className="card-text playjam-text-3 display-8">
                          <Trans
                            i18nKey="homePage.playjam-text"
                            components={{ 1: <br /> }}
                          />{' '}
                        </p>

                        <Link to={`/playjam`} target="_blank">
                          <button type="button" className="wagzak-playjam-btn">
                            <span>
                              <Trans
                                i18nKey="Button.Learnmore"
                                components={{ 1: <br /> }}
                              />
                            </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7 col-12 text-center order-md-2 order-1">
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HomePlayjam;
