import React from 'react';
import './FooterCharacters.css';

function FooterCharacters() { 
    return (
        <div className="footercharacters-style">
            <div className="row d-flex text-center align-items-center justify-content-center">
                <div className="col-md-1 col-1 character-wrapper d-none d-md-block">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Havi.svg" alt="character_img_Havi" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-1 character-wrapper d-none d-md-block">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Dotori.svg" alt="character_img_Dotori" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-3 character-wrapper">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Panpa.svg" alt="character_img_Panpa" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-3 character-wrapper">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Tokki.svg" alt="character_img_Tokki" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-3 character-wrapper">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Lemon.svg" alt="character_img_Lemon" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-3 character-wrapper">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Blue.svg" alt="character_img_Blue" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-1 character-wrapper d-none d-md-block">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Koko.svg" alt="character_img_Koko" className='img-fluid' />
                    </div>
                </div>
                <div className="col-md-1 col-1 character-wrapper d-none d-md-block">
                    <div className="character-container">
                        <img src="/images/characters/wagzakFriends/character_img_Rubi.svg" alt="character_img_Rubi" className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterCharacters;
