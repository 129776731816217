
import React, { useEffect } from 'react';
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';
import zIndex from '@mui/material/styles/zIndex';

function HomeLottieAnimation() {
    useEffect(() => {
        // Get a reference to the Lottie player
        const firstPlayer = document.getElementById("firstLottie");

        // Listen for player load
        firstPlayer.addEventListener("ready", function () {
            // Configure the interactivity library
            create({
                mode: "scroll",
                player: "#firstLottie",
                actions: [{ visibility: [0, 0.2], type: "stop", frames: [0] }, { visibility: [0.2, 0.45], type: "seek", frames: [0, 63] }, { visibility: [0.45, 0.8], type: "loop", frames: [63, 124] }]
            });
        });

        // Select all Lottie players
        const elements = document.getElementsByTagName('lottie-player');

        // Add event listeners for 'play' event
        for (let i = 0; i < elements.length; i++) {
            elements[i].addEventListener('play', (event) => {
                // Check if the shadowRoot and svg are available
                if (event.target.shadowRoot && event.target.shadowRoot.querySelector('svg')) {
                    // Reset the transform style
                    event.target.shadowRoot.querySelector('svg').style.transform = '';
                }
            });

            // Trigger the 'play' event again
            elements[i].play();
        }
    }, []);

    return (
        <div className='d-flex justify-content-end content-item-right'>
            <lottie-player
                id="firstLottie"
                className="lottie-animation1"
                src="https://lottie.host/657a9be3-54a7-41ad-a61d-9ea24756882f/Atpj4F7DWS.json"
                // src="https://lottie.host/7b1be2a3-4815-4867-af85-d3befd80f6e5/ZYqtcF6Nil.json"
                background="transparent"
                speed="1"
                style={{
                    width: '100%',
                    right: '0', margin: '0',
                    paddingRight: '0', 
                }}
                loop
                autoplay
            ></lottie-player>
        </div>
    );
}

export default HomeLottieAnimation;
